/*
 * @Date         : 2020-11-18 09:36:19
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-06 14:30:35
 * @FilePath     : \leXue_manage_pc\src\assets\js\common.js
 */
import validValue from '@/assets/js/validValue.js';

export default class Common {
    constructor(Vue) {
        if (Vue) {
            this.VuePrototype = Vue.prototype;
        }
    }

    /**
     注意：此函数在不同项目中的版本不一，没有通用性

    * @description: 根据rules 提取 inputList里面de value
    * @param {Array} inputList       form配置表
    * @return {Object} 提取结果
    */
    getValueByRules(inputList) {
        let propsValueObj = {};

        inputList.forEach((item) => {
            let { type, label, placeholder, value, id, rules, props, props_id } = item;
            let finalValue = (!value && value !== 0 ? '' : value).toString().trim();

            const isRequired = !!(
                rules &&
                rules.find((item) => {
                    switch (typeof item) {
                        case "string":
                            return item === "required";
                        case "object":
                            return item.checkKey === "required";
                    }
                })
            );

            // console.info('rules', rules);

            if (isRequired === true || finalValue !== "") {
                rules &&
                    rules.forEach((item) => {
                        let checkKey;
                        let failTips;

                        switch (typeof item) {
                            case "string":
                                checkKey = item;
                                failTips = placeholder;
                                break;
                            case "object":
                                checkKey = item.checkKey;
                                failTips = item.failTips;
                                break;
                        }

                        switch (checkKey) {
                            case "required":
                                if (!finalValue === true) {
                                    throw failTips;
                                }
                                break;
                            case "!null":
                                if (!finalValue === true) {
                                    throw failTips;
                                }
                                break;
                            case "num":
                                if (!Number(finalValue)) {
                                    throw label + ': 请输入数字';
                                }
                                break;
                            case "money":
                                if (!validValue.isMoney(finalValue)) {
                                    throw label + ": 金额格式有误";
                                }
                                break;
                            case "mobile":
                                if (!validValue.isMobile(finalValue)) {
                                    throw label + ": 手机号格式有误";
                                }
                                break;
                            case "emial":
                                if (!validValue.isEmial(finalValue)) {
                                    throw label + ": 邮箱格式有误";
                                }
                                break;
                        }
                    });


                if (props_id) {
                    propsValueObj[props_id] = id
                } else {
                    if (props) {
                        (propsValueObj[props] = value);
                    }
                }
            }
        });

        return propsValueObj;
    }

    /**
     * @description:  //根据 inputList 的配置，填充数据
     * @param { Array } inputList form 配置表
     * @param { Object} valueListObj 要填充的值
     */
    fillData({ inputList, valueListObj }) {
        inputList.forEach((item) => {
            const { props, items, isTimeStamp } = item;
            const inputedValue = valueListObj[props];

            if (items) {
                item.value = items.find((item) => item.value == inputedValue).text;
                item.id = inputedValue;
            } else if (isTimeStamp) {
                item.value = formatDate(
                    new Date(inputedValue * 1000),
                    "yyyy-MM-dd hh:mm"
                );
                item.id = inputedValue;
            } else if (item.props_id) {
                // if(typeof inputedValue === 'array'){
                //     inputedValue.forEach(item => {
                //         item.value.push(item);
                //     })
                // }else{
                //     item.value = inputedValue;
                // }

                item.value = inputedValue;

                item.id = valueListObj[item.props_id];
            } else {
                item.value = inputedValue;
            }
        });

        console.info("inputList", inputList);
    };

    // 使用手册：
    // formatDate(
    //     new Date( '时间戳' ),
    //     "yyyy-MM-dd hh:mm" 
    // );
    formatDate(date, fmt) {
        let o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            S: date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) {
            //年份
            fmt = fmt.replace(
                RegExp.$1,
                (date.getFullYear() + "").substr(4 - RegExp.$1.length)
            );
        }
        for (let k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                let str = o[k] + "";
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? str : padLeftZero(str)
                );
            }
        }

        function padLeftZero(str) {
            return ("00" + str).substr(str.length);
        }
        return fmt;
    };

    //限制只能输入金额的格式
    limitInputPriceForm(obj) {
        obj.value = obj.value.replace(/[^\d.]/g, "");//清除"数字"和"."以外的字符
        obj.value = obj.value.replace(/^\./g, "");//验证第一个字符是数字而不是字符
        obj.value = obj.value.replace(/\.{2,}/g, ".");//只保留第一个.清除多余的
        obj.value = obj.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
    };

    //权限校验
    authenCheck(_this, authenKeyList) {
        const getAuthenIsPermitted = _this.$store.getters
            .getAuthenIsPermitted;

        let authenTips = '';

        authenKeyList.forEach(item => {
            const { key, rejectTips } = item;

            if (!getAuthenIsPermitted(key)) {
                authenTips += '、' + rejectTips;
            }
        });

        if (authenTips) {
            throw `此操作需要【${authenTips.replace('、', '')}】权限, 请联系管理员`;
        }
    };





    // //自动补全为两位小数
    // changeDecimal_f(x, decimal) {
    //     var f_x = parseFloat(x, 4);

    //     console.info('f_x', x);
    //     console.info('f_x', f_x);

    //     if (isNaN(f_x)) {
    //         console.error('function:changeTwoDecimal->parameter error');
    //         return false;
    //     }

    //     var f_x = Math.round(x * decimal) / decimal;
    //     var s_x = f_x.toString();
    //     var pos_decimal = s_x.indexOf('.');

    //     if (pos_decimal < 0) {
    //         pos_decimal = s_x.length;
    //         s_x += '.';
    //     }

    //     while (s_x.length <= pos_decimal + 2) {
    //         s_x += '0';
    //     }

    //     return s_x;
    // }
}
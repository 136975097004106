<!--
 * @Description:  教师列表
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    };
  },
  created() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    // 弹出新增/编辑
    async renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(),
        });

        // 获取列表
        let {
          res: { data },
        } = await this.$ajax({
          apiKey: "departmentList",
          data: {
            page: 1,
            paginate: 100,
          },
        });
        let newArr = [];
        for (let i of data) {
          newArr.push({
            label: i.name,
            value: i.id,
          });
        }
        for (let i of inputOptions.inputList) {
          if (i.label === "部门") {
            i.options = newArr;
          }
        }

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          });
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? "新增" : "编辑",
          width: "1000px",
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions;

              let requestOpt = this.$com.getValueByRules(inputList);

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal;
                requestOpt.id = id;
              }

              // 新增/编辑
              const { msg } = await this.$ajax({
                apiKey: "teacherServiceSave",
                data: requestOpt,
              });

              this.$toast({
                msg,
              });

              this.$refs.tableList.init();
            } catch (error) {
              throw error;
            }
          },
          cancel: () => {},
        });
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取输入配置表
    getInputRechargeLevel(inputedValueObj) {
      const _tempValObj = {};

      let getInputRechargeLevel = [
        {
          type: "input",
          label: "教师姓名",
          placeholder: "请输入教师姓名",
          rules: ["required", "!null"],
          value: null,
          props: "name",
          col: "col-4",
        },
        {
          type: "input",
          label: "缩略名",
          placeholder: "请输入缩略名",
          rules: ["required", "!null"],
          value: null,
          props: "sl_name",
          col: "col-4",
        },
        {
          type: "radio",
          label: "性别",
          placeholder: "",
          rules: ["!null"],
          value: 1,
          props: "gender",
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
          col: "col-4",
        },
        {
          type: "input",
          label: "手机号码",
          placeholder: "请输入手机号码",
          rules: ["required", "!null"],
          value: null,
          props: "mobile",
          col: "col-4",
        },
        {
          type: "select",
          label: "部门",
          placeholder: "请选择部门",
          rules: ["!null"],
          value: "",
          props: "department_id",
          options: [],
          col: "col-4",
        },
        {
          type: "datePicker",
          label: "入职时间",
          placeholder: "请选择入职时间",
          maxLength: 40,
          suffixIcon: "el-icon-date",
          rules: ["!null"],
          value: null,
          props: "entry_date", //end_time	是	string	结班时间
          col: "col-4",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd"
            );

            item.value = formatDate;
          },
        },
        {
          type: "radio",
          label: "类型",
          placeholder: "请选择类型",
          rules: ["required", "!null"],
          value: 0,
          props: "type",
          options: [
            {
              label: "默认",
              value: 0,
            },
            // 给七彩童年小程序选择老师用的
            {
              label: "客服老师",
              value: 1,
            },
          ],
          col: "col-4", //显示的宽度
        },
        {
          type: "radio",
          label: "小程序(教务)角色类型",
          placeholder: "请选择类型",
          rules: ["required", "!null"],
          value: 3,
          props: "miniprogram_type",
          options: [
            {
              label: "总经理",
              value: 1,
            },
            {
              label: "项目经理",
              value: 2,
            },
            {
              label: "执行人",
              value: 3,
            },
          ],
          col: "col-4", //显示的宽度
        },
        {
          type: "headerImg",
          label: "头像",
          placeholder: "请上传管理员头像",
          value: "",
          //   defaultVal: "storage/20200812/5f33b6d6ec3b2.png",
          rules: ["!null"],
          props: "avatar",
          col: "col-4",
        },
      ];

      return getInputRechargeLevel;
    },

    getTableOptions() {
      this.getDepartmentList();

      return {
        authenKey: "teacherServiceList",
        search: {
          inputList: [
            {
              type: "input",
              label: "姓名",
              placeholder: "",
              value: "",
              props: "name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "手机号码",
              placeholder: "",
              value: "",
              props: "mobile",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "部门名称",
              placeholder: "",
              value: "",
              props: "department_name",
              rules: ["!null"],
              options: [],
            },
          ],
        },
        headers: [
          { label: "教师姓名", props: "name" },
          { label: "缩略名", props: "sl_name" },
          { label: "性别", props: "gender_str" },
          { label: "手机号码", props: "mobile" },
          { label: "部门", props: "department_name" },
          { label: "类型", props: "type_str" },
          { label: "入职时间", props: "entry_date" },
          { label: "小程序(教务)角色类型", props: "miniprogram_type_str" },
          { label: "状态", props: "status_str" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增",
            authenKey: "teacherServiceAdd",
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              });
            },
          },
        ],
        actionBtns: [
          {
            type: "multiState", //多状态按钮
            props: "status",
            authenKey: "teacherServiceChangeAbleUseStatus",
            options: [
              {
                label: "启用",
                value: 2,
                color: "success",
              },
              {
                label: "禁用",
                value: 1,
                color: "warning",
              },
            ],
            onTap: async (btnObj, tableItem) => {
              this.$resureDialog({
                ok: async () => {
                  try {
                    const { id, status } = tableItem;
                    const afterStatus = Number(status) === 1 ? 2 : 1;

                    // 启用/禁用
                    const { msg } = await this.$ajax({
                      apiKey: "teacherServiceChangeStatus",
                      data: {
                        id: id,
                        status: afterStatus,
                      },
                    });

                    tableItem.status = afterStatus;

                    this.$toast({
                      msg,
                    });
                    this.$refs.tableList.init();
                  } catch (error) {
                    this.$catchError(error);
                  }
                },
              });
            },
          },
          {
            title: "编辑",
            color: "deepBlue",
            authenKey: "teacherServiceEdit",
            onTap: (options) => {
              this.renderEditDialog({
                type: 1,
                inputedVal: options,
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "teacherServiceDel",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "teacherServiceDel",
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              });
            },
          },
        ],
        getData: async (options) => {
          console.log("获取列表", options);
          // 获取列表
          let ret = await this.$ajax({
            apiKey: "teacherServiceList",
            data: {
              ...options,
            },
          });

          return ret;
        },
      };
    },

    async getDepartmentList() {
      // 获取列表
      let {
        res: { data },
      } = await this.$ajax({
        apiKey: "departmentList",
        data: {
          page: 1,
          paginate: 100,
        },
      });
      console.log("部门列表", data);
      let newArr = [];
      for (let i of data) {
        newArr.push({
          value: i.name,
        });
      }

      for (let i of this.tableOptions.search.inputList) {
        if (i.props === "department_name") {
          // console.log('i', i)
          i.options = newArr;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-27 09:55:43
 * @FilePath     : \leXue_manage_pc\src\views\member\memberConsumeRecordList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "member_consumptList",
        headers: [
          { label: "订单号", props: "order_sn" },
          { label: "消费金额", props: "money" },
          { label: "创建时间", props: "created_at" },
          { label: "备注", props: "remark" },
        ],
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          const { parentID } = this.initOptions;

          let ret = await this.$ajax({
            apiKey: "getMemberConsumeList",
            data: {
              ...options,
              pid: parentID || "", //pid	否	int	家长id
            },
          });

          return ret;
        },
      };

      return getTableOptions;
    },
  },
};
</script>

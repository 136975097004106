<template>
  <div class="pageBox">
    <div
      class="col overflow-scroll-y h-100"
      style="padding: 20px; padding-bottom: 100px;"
    >
      <!-- 基本信息 -->
      <infoWrapper class="my-10" title="基本信息" style="height: auto;">
        <div class="lineForm px-20 pt-20">
          <el-form
            inline
            :model="infoForm"
            :rules="infoFormRules"
            ref="infoFormRef"
            size="mini"
            label-width="auto"
            label-position="top"
            :disabled="pageType === 2"
          >
            <el-form-item label="模板名称：" prop="temp_name">
              <el-input
                v-model="infoForm.temp_name"
                placeholder="请输入模板名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="模板封面图：" prop="logo">
              <!-- 上传单个图片 -->
              <div class="upload-header-img-bar">
                <div
                  class="position-relative cursor-pointer header-img"
                  :class="!infoForm.logo && 'add-icon'"
                  @click="pageType !== 2 && handleTriggerUploadHeaderImg()"
                >
                  <img
                    class="imgCover"
                    v-if="infoForm.logo"
                    :src="imgAddHostUrl(infoForm.logo)"
                  />
                </div>
                <chooseAndUploadLoaclImg
                  ref="chooseLoactImgDom"
                  @change="saveUploadImgData($event)"
                ></chooseAndUploadLoaclImg>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </infoWrapper>
      <!-- 项目信息 -->
      <infoWrapper class="my-10" title="项目信息">
        <div class="lineForm px-20 pt-20">
          <el-form
            inline
            :model="projectInfoForm"
            :rules="projectInfoFormRules"
            ref="projectInfoFormRef"
            label-width="auto"
            label-position="top"
            size="mini"
            :disabled="pageType === 2"
          >
            <el-form-item label="项目名称：" prop="name">
              <el-input
                v-model="projectInfoForm.name"
                placeholder="请输入项目名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="是否公开：" prop="is_open">
              <el-select
                v-model="projectInfoForm.is_open"
                clearable
                placeholder="请选择"
              >
                <el-option label="所有人可见" :value="2"></el-option>
                <el-option label="仅执行人可见" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="是否需要审核：" prop="need_review">
              <el-select
                v-model="projectInfoForm.need_review"
                clearable
                placeholder="请选择"
              >
                <el-option label="是" :value="2"></el-option>
                <el-option label="否" :value="1"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="项目概述：" prop="info" style="width: 95%">
              <el-input
                clearable
                v-model="projectInfoForm.info"
                placeholder="请输入项目概述"
                type="textarea"
                :autosize="{ minRows: 4 }"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </infoWrapper>
      <!-- 任务信息 -->
      <infoWrapper class="my-10" title="任务信息">
        <div class="lineForm  taskInfoBox">
          <div calss="taskInfoList">
            <div
              class="taskInfoItem pa-20"
              v-for="(item, index) in taskInfoList"
              :key="index"
              style="margin-bottom: 12px;"
            >
              <!-- 标题 -->
              <div class="taskInfo_titleBox">
                <div class="taskInfo_title">任务{{ index + 1 }}</div>
                <template v-if="pageType !== 2">
                  <!--  :disabled="taskInfoList.length === 1" -->
                  <el-button
                    type="danger"
                    size="mini"
                    @click="taskInfoItemDel(index)"
                  >
                    删除
                  </el-button>
                  <el-button
                    type="primary"
                    icon="el-icon-caret-top"
                    circle
                    size="mini"
                    @click="taskInfoItemUpGo(index)"
                    :disabled="index === 0"
                  ></el-button>
                  <el-button
                    type="primary"
                    icon="el-icon-caret-bottom"
                    circle
                    size="mini"
                    @click="taskInfoItemDownGo(index)"
                    :disabled="index === taskInfoList.length - 1"
                  ></el-button>
                </template>
              </div>
              <!-- 表单 -->
              <div class="taskInfoItem">
                <el-form
                  inline
                  :model="item"
                  :rules="taskInfoRules"
                  :ref="'taskInfoFormRef_' + index"
                  label-width="auto"
                  label-position="top"
                  size="mini"
                  :disabled="pageType === 2"
                >
                  <el-form-item label="任务名称：" prop="task_name">
                    <el-input
                      v-model="item.task_name"
                      placeholder="请输入任务名称"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="优先级：" prop="task_level">
                    <el-select
                      v-model="item.task_level"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option label="暂缓" :value="1"></el-option>
                      <el-option label="普通" :value="2"></el-option>
                      <el-option label="紧急" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="循环：" prop="loop_level">
                    <el-select
                      v-model="item.loop_level"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option label="不循环" :value="1"></el-option>
                      <el-option label="每天" :value="2"></el-option>
                      <el-option label="每周" :value="3"></el-option>
                      <el-option label="每两周" :value="4"></el-option>
                      <el-option label="工作日" :value="5"></el-option>
                      <el-option label="非工作日" :value="6"></el-option>
                      <el-option label="每月" :value="7"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="是否公开：" prop="task_is_open">
                    <el-select
                      v-model="item.task_is_open"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option label="仅执行人可见" :value="1"></el-option>
                      <el-option label="所有人可见" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="任务概述："
                    prop="task_intro"
                    style="width: 95%"
                  >
                    <el-input
                      clearable
                      v-model="item.task_intro"
                      placeholder="请输入任务概述"
                      type="textarea"
                      :autosize="{ minRows: 4 }"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <!-- 新增按钮 -->
          <div class="taskInfoAddBtn">
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              size="mini"
              @click="taskInfoItemAdd"
              v-if="pageType !== 2"
              >新增任务</el-button
            >
          </div>
        </div>
      </infoWrapper>
    </div>
    <!-- 模块：底部操作按钮 -->
    <div class="bottomTheBox">
      <el-button size="medium" @click="$router.go(-1)">
        返回
      </el-button>
      <el-button
        v-if="pageType !== 2"
        size="medium"
        type="primary"
        @click="handleSubmit"
      >
        <template v-if="pageType === 0">新增</template>
        <template v-else-if="pageType === 1">编辑</template>
      </el-button>
    </div>
  </div>
</template>

<script>
import footerBtnModules from "@/components/footerBtnModules.vue";
import infoWrapper from "@/components/infoWrapper.vue";
import chooseAndUploadLoaclImg from "@/components_global/chooseAndUploadLoaclImg.vue";

export default {
  components: {
    infoWrapper,
    footerBtnModules,
    chooseAndUploadLoaclImg,
  },
  data() {
    return {
      pageType: 0,
      // 基本信息
      infoForm: {
        temp_name: "",
        logo: "",
      },
      infoFormRules: {
        temp_name: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
      },
      // 项目信息
      projectInfoForm: {
        name: "",
        is_open: "",
        need_review: "",
        info: "",
      },
      projectInfoFormRules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        is_open: [
          { required: true, message: "请选择是否公开", trigger: "change" },
        ],
        // need_review: [
        //   { required: true, message: "请选择是否需要审核", trigger: "change" },
        // ],
        info: [{ required: true, message: "请输入项目概述", trigger: "blur" }],
      },
      // 任务信息
      taskInfoList: [],
      taskInfoRules: {
        task_name: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        task_level: [
          { required: true, message: "请选择优先级", trigger: "change" },
        ],
        loop_level: [
          { required: true, message: "请选择循环", trigger: "change" },
        ],
        task_is_open: [
          { required: true, message: "请选择是否公开", trigger: "change" },
        ],
        task_intro: [
          { required: true, message: "请输入任务概述", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  async mounted() {
    try {
      let { pageType, id } = this.$route.query;

      pageType = this.pageType = Number(pageType);

      //1：编辑 2：查看
      if (pageType === 1 || pageType === 2) {
        const { res } = await this.$ajax({
          apiKey: "projectTemplateGetInfo",
          data: {
            temp_id: id,
          },
        });
        console.log("getInfo", res);
        this.infoForm = res;
        this.projectInfoForm = res.project;
        this.taskInfoList = res.task;
        delete this.infoForm.project;
        delete this.infoForm.task;
        delete this.infoForm.deleted_at;
      } else {
        this.taskInfoItemAdd();
      }
    } catch (error) {
      this.$catchError(error);
    }
  },
  methods: {
    //提交
    async handleSubmit(pageType) {
      // 验证-基本信息
      let infoFormValidate = await this.$refs.infoFormRef.validate();
      if (!infoFormValidate) return false;
      console.log("infoForm-ok", this.infoForm);

      // 验证-项目信息
      let projectInfoFormValidate = await this.$refs.projectInfoFormRef.validate();
      if (!projectInfoFormValidate) return false;
      console.log("projectInfoForm-ok", this.projectInfoForm);

      // 验证-任务信息
      if (this.taskInfoList.length) {
        await this.taskInfoItemValidate();
        console.log("taskInfoList-ok", this.taskInfoList);
      }

      try {
        let tempParamObj = {
          project: this.projectInfoForm,
          task: this.taskInfoList,
        };
        let form = {
          ...this.infoForm,
          temp_param: JSON.stringify(tempParamObj),
        };
        console.log("form", form);
        let { msg, res } = await this.$ajax({
          apiKey: "projectTemplateSave",
          data: form,
        });
        this.$toast({
          msg,
        });

        this.$router.go(-1);
        // 更新列表
        this.$bus_unique.emit("updateProjectTemplateList");
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 任务信息
    // 新增
    taskInfoItemAdd() {
      this.taskInfoList.push({
        task_name: "",
        task_level: "",
        loop_level: "",
        task_is_open: "",
        task_intro: "",
      });
    },
    taskInfoItemDel(index) {
      this.$confirm("是否删除任务" + (index + 1) + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.taskInfoList.splice(index, 1);
        this.$toast({
          msg: "删除成功",
        });
      });
    },
    // 上移
    taskInfoItemUpGo(index) {
      this.taskInfoList[index] = this.taskInfoList.splice(
        index - 1,
        1,
        this.taskInfoList[index]
      )[0];
    },
    // 下移
    taskInfoItemDownGo(index) {
      this.taskInfoList[index] = this.taskInfoList.splice(
        index + 1,
        1,
        this.taskInfoList[index]
      )[0];
    },
    // 验证-任务信息
    taskInfoItemValidate() {
      return new Promise((reslove, reject) => {
        let promiseArr = this.taskInfoList.map((ele, index) =>
          this.$refs[`taskInfoFormRef_${index}`][0].validate()
        );
        Promise.all(promiseArr)
          .then((res) => {
            // console.log("任务信息-验证通过");
            reslove();
          })
          .catch((err) => {
            // console.log("任务信息-验证未通过");
            reject(new Error("任务信息-验证未通过"));
          });
      });
    },

    handleTriggerUploadHeaderImg() {
      this.$refs.chooseLoactImgDom.chooseImg();
    },
    saveUploadImgData(e) {
      console.info("saveUploadImgData-e", e);
      this.infoForm.logo = e;
    },
    //图片增加域名
    imgAddHostUrl(imgUrl) {
      return /(http|assets)/.test(imgUrl) ? imgUrl : this.$imgBaseUrl + imgUrl;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.pageBox {
  margin: -1.25rem;
  position: relative;
  height: calc(100% + 2.5rem);

  .main-content-div {
    height: initial;
  }
}

::v-deep .lineForm {
  .el-form-item {
    width: 30%;

    .el-input,
    .el-select {
      width: 100%;
    }
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .el-form--inline .el-form-item {
    margin-right: 26px;
  }
}

.bottomTheBox {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 30px;
  width: calc(100% - 208px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(218, 218, 218, 0.5);

  .el-button {
    margin: 0 14px;
  }
}

.taskInfoBox {
  background: #eef3f6;

  .taskInfoItem {
    background: #fff;

    .taskInfo_titleBox {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding-bottom: 14px;
      margin-bottom: 20px;

      .taskInfo_title {
        font-size: 15px;
        color: #000;
        margin-right: 20px;
      }
    }
  }

  .taskInfoAddBtn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.upload-header-img-bar {
  font-size: 0.75rem;
  color: #010101;

  .header-img {
    width: 5.625rem;
    height: 5.688rem;
    border: 1px dashed #ddd;
    border-radius: 0.25rem;
    overflow: hidden;

    &.add-icon::before,
    &.add-icon::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: "";
      display: block;
      width: 1rem;
      height: 2px;
      background-color: #ddd;
    }
    &::after {
      transform: rotate(90deg);
    }

    img {
      border: none;
    }
  }
}
</style>

import { render, staticRenderFns } from "./addOrEditOrder.vue?vue&type=template&id=70fc21f2&scoped=true&"
import script from "./addOrEditOrder.vue?vue&type=script&lang=js&"
export * from "./addOrEditOrder.vue?vue&type=script&lang=js&"
import style0 from "./addOrEditOrder.vue?vue&type=style&index=0&id=70fc21f2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fc21f2",
  null
  
)

export default component.exports
<!--
 * @Date         : 2020-11-21 17:05:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-16 11:45:58
 * @FilePath     : \leXue_manage_pc\src\views\goods\goodsList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      tableOptions: null,
    };
  },
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    //监听：更新商品列表
    this.$bus_unique.on("updateGoodsList", "goodsList", () => {
      this.$refs.tableList.init();
    });
  },
  methods: {
    getTableOptions() {
      const { isSelect, onSelect } = this.initOptions; //选择模式

      let configureOptions;

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              isRender: true,
              onTap: (options) => {
                onSelect(options);
              },
            },
          ],
          titleBtns: [
            {
              title: "查询",
              type: "filter",
              authenKey: "goods_add",
            },
          ],
        };
      } else {
        configureOptions = {
          titleBtns: [
            {
              title: "查询",
              type: "filter",
            },
            {
              title: "新增产品",
              authenKey: "goods_add",
              onTap: () => {
                this.$router.push({
                  name: "addOrEditGoods",
                  query: {
                    pageType: 0,
                  },
                });
              },
            },
          ],
          actionBtns: [
            {
              title: "查看",
              color: "cyan",
              authenKey: "goods_details",
              onTap: (options) => {
                this.$router.push({
                  name: "addOrEditGoods",
                  query: {
                    pageType: 2,
                    id: options.id,
                  },
                });
              },
            },
            {
              title: "编辑",
              color: "cyan",
              authenKey: "goods_edit",
              onTap: (options) => {
                this.$router.push({
                  name: "addOrEditGoods",
                  query: {
                    pageType: 1,
                    id: options.id,
                  },
                });
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "goods_del",
              onDelete: async (options) => {
                return await this.$ajax({
                  responseAll: true,
                  apiKey: "delProduct",
                  data: {
                    id: options.id,
                  },
                });
              },
            },
            {
              type: "multiState", //多状态按钮
              props: "status",
              authenKey: "goods_isForbidden",
              options: [
                {
                  label: "启用",
                  value: 2,
                  color: "success",
                },
                {
                  label: "禁用",
                  value: 1,
                  color: "warning",
                },
              ],
              onTap: async (btnObj, tableItem) => {
                this.$resureDialog({
                  ok: async () => {
                    try {
                      const { id, status } = tableItem;
                      const afterStatus = Number(status) === 1 ? 2 : 1;

                        const { msg } = await this.$ajax({
                        apiKey: "transIsForbidden",
                        data: {
                          id: id, //id	是	int	产品id
                          status: afterStatus, //status	是	int	类型 1启用 2删除(暂无) 3禁用
                        },
                      });

                      tableItem.status = afterStatus;

                      this.$toast({
                        msg,
                      });
                    } catch (error) {
                      this.$catchError(error);
                    }
                  },
                });
              },
            },
            // {
            //   type: "multiState", //多状态按钮
            //   props: "sale",
            //   authenKey: "goods_isOnSale",
            //   options: [
            //     {
            //       label: "上架",
            //       value: 2,
            //       color: "success",
            //     },
            //     {
            //       label: "下架",
            //       value: 1,
            //       color: "warning",
            //     },
            //   ],
            //   onTap: async (btnObj, tableItem) => {
            //     this.$resureDialog({
            //       ok: async () => {
            //         try {
            //           const { id, sale } = tableItem;
            //           const afterSaleStatus = Number(sale) === 1 ? 2 : 1

            //           const { msg } = await this.$ajax({
            //             apiKey: "transIsOnSale",
            //             data: {
            //               id: id, //id	是	int	产品id
            //               sale: afterSaleStatus, //sale	是	int	类型 1启用 2删除(暂无) 3禁用
            //             },
            //           });

            //           tableItem.sale = afterSaleStatus

            //           this.$toast({
            //             msg,
            //           });
            //         } catch (error) {
            //           this.$com.catchError(error);
            //         }
            //       },
            //     });
            //   },
            // },
          ],
        };
      }

      return {
        authenKey: "goods_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "产品名称",
              placeholder: "请输入产品名称",
              value: "",
              props: "name",
              rules: ["!null"],
            },
            {
              type: "select",
              label: "类别",
              placeholder: "请选择类别",
              value: "",
              props: "is_course",
              rules: ["!null"],
              options: [
                {
                  label: "实物",
                  value: 0,
                },
                {
                  label: "课程",
                  value: 1,
                },
              ],
            },
            ...(!isSelect
              ? [
                  {
                    type: "select",
                    label: "是否上架",
                    placeholder: "请选择是否上架",
                    value: "",
                    props: "sale", //sale	否	int	1-上架 2-下架
                    rules: ["!null"],
                    options: [
                      {
                        label: "是",
                        value: 1,
                      },
                      {
                        label: "否",
                        value: 2,
                      },
                    ],
                  },
                  {
                    type: "select",
                    label: "是否禁用",
                    placeholder: "请选择是否禁用",
                    value: "",
                    props: "status", // status	否	int	1-正常 2-禁用
                    rules: ["!null"],
                    options: [
                      {
                        label: "正常",
                        value: 1,
                      },
                      {
                        label: "禁用",
                        value: 2,
                      },
                    ],
                  },
                ]
              : []),
          ],
        },
        headers: [
          { label: "产品名称", props: "name" },
          { label: "产品分类", props: "type_name" },
          { label: "上次编辑时间", props: "updated_at" },
        ],
        ...configureOptions,
        getData: async (options) => {
          let requestRet;
          let { res } = (requestRet = await this.$ajax({
            apiKey: "getProdutList",
            data: {
              ...options,
              ...(isSelect
                ? {
                    sale: 1,
                    status: 1,
                  }
                : {}),
            },
          }));

          return requestRet;
        },
      };
    },
  },
};
</script>

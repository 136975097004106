<!--
 * @Description:  修改密码
-->
<template>
  <mainContentDiv v-if="initOptions_inputCenter">
    <div class="w-50 pl-5 pt-5 mt-30 mb-40 mx-40">
      <inputCenter
        ref="inputCenter"
        :initOptions="initOptions_inputCenter"
      ></inputCenter>
      <comBtn class="mr-20" color="cyan" @click="handleSaveData()"
        >更改密码</comBtn
      >
    </div>
  </mainContentDiv>
</template>

<script>
export default {
  data() {
    return {
      initOptions_inputCenter: null,
      openDialogObj: null,
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      try {
        let inputList = await this.getNativeTemplateinputCenter()
        let initOptions_inputCenter = null

        initOptions_inputCenter = {
          inputList,
        }
        this.initOptions_inputCenter = initOptions_inputCenter
      } catch (error) {
        this.$toast({
          color: 'error',
          msg: error,
        })
      }
    },
    async handleSaveData() {
      try {
        let requestOptions = await this.$com.getValueByRules(
          this.initOptions_inputCenter.inputList
        )

        console.info('requestOptions', requestOptions)

        let { id } = this.$route.query

        //提交
        const { msg } = await this.$ajax({
          responseAll: true,
          apiKey: 'change_admin_pd',
          data: {
            admin_id: id,
            pd: requestOptions.password,
          },
        })

        this.$router.back(-1)

        this.$toast({
          color: 'success',
          msg,
        })
      } catch (error) {
        this.$toast({
          color: 'error',
          msg: error,
        })
      }
    },

    //原始列表数据
    getNativeTemplateinputCenter() {
      return [
        {
          ref: 'password',
          type: 'input',
          label: '密码',
          placeholder: '请输入密码',
          maxLength: 10,
          value: '',
          defaultVal: '',
          rules: ['required', '!null'],
          props: 'password',
        },
      ]
    },
  },
  computed: {
    submitBtnAuthen() {
      const pageType = this.pageType
      const getAuthenIsPermitted = this.$store.getters.getAuthenIsPermitted

      let submitBtnAuthen = null

      switch (pageType) {
        case 0:
          if (getAuthenIsPermitted('admin_add') === true) {
            submitBtnAuthen = {
              title: '新增',
            }
          }
          break
        case 1:
          if (getAuthenIsPermitted('admin_edit') === true) {
            submitBtnAuthen = {
              title: '保存编辑',
            }
          }
          break
      }

      return submitBtnAuthen
    },
  },
}
</script>

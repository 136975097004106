<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-08 09:30:55
 * @FilePath     : \leXue_manage_pc\src\views\member\rechargeRuleList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
    <centerDialog ref="centerDialog">
      <inputCenter :initOptions="initOptions_input"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import validValue from '@/assets/js/validValue.js'

export default {
  data() {
    return {
      tableOptions: null,
      initOptions_input: null,
    }
  },
  created() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    // 弹出新增/编辑会员等级
    renderEditDialog({ type, inputedVal }) {
      try {
        let inputOptions = (this.initOptions_input = {
          inputList: this.getInputRechargeLevel(),
        })

        if (inputedVal) {
          this.$com.fillData({
            inputList: this.initOptions_input.inputList,
            valueListObj: inputedVal,
          })
        }

        this.$refs.centerDialog.render({
          title: type === 0 ? '新增充值规则' : '编辑充值规则',
          width: '600px',
          verifyInputListIsChange: inputOptions.inputList,
          ok: async () => {
            try {
              const { inputList } = inputOptions

              let requestOpt = this.$com.getValueByRules(inputList)

              // 1:新增 2:编辑
              if (type === 1) {
                const { id } = inputedVal
                requestOpt.id = id
              }

              const { msg } = await this.$ajax({
                apiKey: 'addOrEditMemberRechargeRule',
                data: requestOpt,
              })

              this.$toast({
                msg,
              })

              this.$refs.tableList.init()
            } catch (error) {
              throw error
            }
          },
          cancel: () => {},
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    //获取输入配置表
    getInputRechargeLevel(inputedValueObj) {
      const _tempValObj = {}

      let getInputRechargeLevel = [
        //
        (_tempValObj.memberLevel = {
          type: 'select',
          label: '会员等级',
          placeholder: '请搜索/选择会员等级',
          rules: ['required', '!null'],
          value: '',
          props: 'level_id',
          col: 'col-6',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'getMemberLevelList',
                data: {
                  name: keyword || '',
                  status: 1, //1充值规则列表 2其他列表【必填】
                },
              })

              _tempValObj.memberLevel.options = data.map((item) => {
                const { id, name } = item

                return {
                  label: name,
                  value: id,
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
          onChange: (val, thisObj) => {
            thisObj.id = val
          },
        }),
        {
          type: 'radio',
          label: '是否启用',
          placeholder: '请选择是否启用',
          rules: ['required', '!null'],
          value: 1,
          props: 'status',
          col: 'col-6',
          options: [
            {
              label: '是',
              value: 1,
            },
            {
              label: '否',
              value: 2,
            },
          ],
        },
        {
          type: 'datePicker',
          label: '生效日期',
          placeholder: '请选择生效日期',
          maxLength: 40,
          suffixIcon: 'el-icon-date',
          rules: ['required', '!null'],
          value: null,
          props: 'start_date', //start_date	是	string	生效日期
          col: 'col-6',
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd'
            )

            item.value = formatDate
          },
        },
        {
          type: 'datePicker',
          label: '截止日期',
          placeholder: '请选择截止日期',
          maxLength: 40,
          suffixIcon: 'el-icon-date',
          rules: ['required', '!null'],
          value: null,
          props: 'end_date', //end_date	是	string	截止日期
          col: 'col-6',
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd'
            )

            item.value = formatDate
          },
        },
        {
          type: 'input',
          label: '充值金额',
          placeholder: '请输入充值金额',
          maxLength: 40,
          rules: ['required', '!null'],
          value: null,
          props: 'recharge_money',
          col: 'col-6',
          onChange: (item) => {
            validValue.inputMoney(item)
          },
        },
        {
          type: 'input',
          label: '赠送金额',
          placeholder: '请输入赠送金额',
          maxLength: 40,
          rules: ['required', '!null'],
          value: null,
          props: 'give_money',
          col: 'col-6',
          onChange: (item) => {
            validValue.inputMoney(item)
          },
        },
      ]

      _tempValObj.memberLevel.remoteMethod()

      return getInputRechargeLevel
    },

    getTableOptions() {
      return {
        authenKey: 'memberRechargeRules_list',
        search: {
          inputList: [
            {
              type: 'input',
              label: '适用会员',
              placeholder: '',
              value: '',
              props: 'name',
              rules: ['!null'],
            },
          ],
        },
        headers: [
          { label: '适用会员', props: 'name' },
          { label: '生效日期', props: 'start_date' },
          { label: '截止日期', props: 'end_date' },
          { label: '充值金额', props: 'recharge_money' },
          { label: '赠送金额', props: 'give_money' },
          { label: '状态', props: 'status_str' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '新增',
            authenKey: 'memberRechargeRules_add',
            onTap: () => {
              this.renderEditDialog({
                type: 0,
              })
            },
          },
        ],
        actionBtns: [
          {
            type: 'multiState', //多状态按钮
            props: 'status',
            authenKey: 'memberRechargeRules_changeAbleUseStatus',
            options: [
              {
                label: '启用',
                value: 2,
                color: 'success',
              },
              {
                label: '禁用',
                value: 1,
                color: 'warning',
              },
            ],
            onTap: async (btnObj, tableItem) => {
              this.$resureDialog({
                ok: async () => {
                  try {
                    const { id, status } = tableItem
                    const afterStatus = Number(status) === 1 ? 2 : 1

                    const { msg } = await this.$ajax({
                      apiKey: 'changeMemberRuleIsUseAble',
                      data: {
                        id: id,
                        status: afterStatus,
                      },
                    })

                    tableItem.status = afterStatus

                    this.$toast({
                      msg,
                    })
                    this.$refs.tableList.init()
                  } catch (error) {
                    this.$catchError(error)
                  }
                },
              })
            },
          },
          {
            title: '编辑',
            color: 'deepBlue',
            authenKey: 'memberRechargeRules_edit',
            onTap: (options) => {
              this.renderEditDialog({
                type: 1,
                inputedVal: options,
              })
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'memberRechargeRules_del',
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: 'delMemberRechargeRule',
                data: {
                  id: options.id, //cid	是	int	班级id
                },
              })
            },
          },
        ],
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: 'getMemberRechargeRuleList',
            data: {
              ...options,
              status: 2,
            },
          })

          return ret
        },
      }
    },
  },
}
</script>

<!--
 * @Date         : 2021-03-16 14:58:13
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-16 16:48:26
 * @FilePath     : \leXue_manage_pc\src\views\order\refundInfoModules.vue
-->
<template>
  <infoWrapper class="my-10" title="退款信息">
    <div class="px-20 py-10 w-75">
      <inputCenter :initOptions="inputList_refundReason"></inputCenter>
      <inputCenter
        v-if="inputList_refundReason_more"
        :initOptions="inputList_refundReason_more"
      ></inputCenter>
    </div>
  </infoWrapper>
</template>

<script>
import infoWrapper from "@/components/infoWrapper.vue";

export default {
  components: {
    infoWrapper,
  },
  data() {
    return {
      inputList_refundReason: {
        inputList: this.getInputList_refund(),
      },
      inputList_refundReason_more: {
        inputList: this.getInputList_moreRefundInfo(1), //默认退回到余额
      },
    };
  },
  methods: {
    getValueByParent() {
      const toSubmitOpt = {
        ...this.$com.getValueByRules(this.inputList_refundReason.inputList),
        ...this.$com.getValueByRules(
          this.inputList_refundReason_more.inputList
        ),
      };

      console.info("toSubmitOpt", toSubmitOpt);

      return toSubmitOpt;
    },
    getInputList_refund() {
      const _tempValObj = {};
      const timeStamp = new Date().getTime();
      const dateStr = this.$com.formatDate(
        new Date(timeStamp),
        "yyyy-MM-dd hh:mm:ss "
      );

      const inputList = [
        {
          type: "datePicker",
          label: "退款日期",
          placeholder: "请选择退款日期",
          maxLength: 40,
          rules: ["required", "!null"],
          value: dateStr,
          props: "refund_date",
          col: "col-3",
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        (_tempValObj.refundReason = {
          type: "select",
          label: "退款原因",
          placeholder: "请搜索/选择退款原因",
          rules: ["required", "!null"],
          value: null,
          props: "refund_reason_id",
          col: "col-3",
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: "getRefundReasonList",
                data: {
                  name: keyword || "", //name	否	string	学校名称
                },
              });

              _tempValObj.refundReason.options = data.map((item) => {
                const { id, name } = item;

                return {
                  label: name,
                  value: id,
                };
              });
            } catch (error) {
              this.$catchError(error);
            }
          },
        }),
        {
          type: "radio",
          label: "退回方式",
          placeholder: "请选择退回方式",
          rules: ["required", "!null"],
          value: 1, //默认退回到余额
          props: "refund_type",
          options: [
            {
              label: "余额",
              value: 1,
            },
            {
              label: "银行卡",
              value: 2,
            },
            {
              label: "微信",
              value: 4,
            },
            {
              label: "支付宝",
              value: 5,
            },
            {
              label: "现金",
              value: 3,
            },
          ],
          onChange: (newVal, thisObj) => {
            const existedMoreRefundReason = this.inputList_refundReason_more;
            const moreRefundInfo = this.getInputList_moreRefundInfo(newVal);

            //将输入好的值回填
            const inputedValue = {};
            const { inputList } = existedMoreRefundReason;

            inputList.forEach(
              (item) => (inputedValue[item.props] = item.value)
            );

            console.info("inputedValue", inputedValue);

            this.$com.fillData({
              inputList: moreRefundInfo,
              valueListObj: inputedValue,
            });

            this.inputList_refundReason_more = {
              inputList: moreRefundInfo,
            };
          },
        },
      ];

      _tempValObj.refundReason.remoteMethod();

      return inputList;
    },

    getInputList_moreRefundInfo(currentCommand) {
      const moreRefundReason = [
        {
          type: "input",
          label: "退回账号姓名",
          placeholder: "请输入退回账号姓名",
          maxLength: 50,
          rules: ["required", "!null"],
          value: null,
          props: "account_name",
          col: "col-3",
          useComandList: [2, 4, 5],
        },
        {
          type: "input",
          label: "退回账号",
          placeholder: "请输入退回账号",
          maxLength: 50,
          rules: ["required", "!null"],
          value: null,
          props: "account_number",
          col: "col-3",
          useComandList: [2, 4, 5],
        },
        {
          type: "input",
          label: "开户行",
          placeholder: "请输入开户行",
          maxLength: 50,
          rules: ["required", "!null"],
          value: null,
          props: "account_bank",
          col: "col-3",
          useComandList: [2],
        },
        {
          type: "input",
          label: "备注",
          placeholder: "请输入备注",
          maxLength: 50,
          rules: ["!null"],
          value: null,
          props: "remark",
          col: "col-3",
          useComandList: [1, 2, 3, 4, 5],
        },
      ];

      return moreRefundReason.filter((item) =>
        item.useComandList.find((item) => item === currentCommand)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
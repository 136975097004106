<!--
 * @Description: 任务内容
-->
<template>
  <div
    class="event-item"
    :class="{ selected: showCard == eventData.id }"
    :style="`background-color: ${eventData.background_color || '#62aef3'}`"
  >
    <p class="info" style="font-weight: bold;">
      {{ eventData.class_name }}（{{ eventData.teacher_sl_name }}）
    </p>
    <p class="info">{{ eventData.time_interval }}</p>
    <p class="info">教室：{{ eventData.classroom || '-' }}</p>
    <!-- 点击任务详情 -->
    <div
      id="card"
      :class="cardClass"
      v-if="eventData && showCard == eventData.id"
      @click.stop
    >
      <div class="card_info">
        <div class="infoLine">
          <div class="infoLine_label">班级名称：</div>
          <div class="infoLine_value">
            {{ eventData.class_name }}（{{ eventData.teacher_sl_name }}）
          </div>
        </div>
        <div class="infoLine">
          <div class="infoLine_label">教师名称：</div>
          <div class="infoLine_value">{{ eventData.teacher_name }}</div>
        </div>
        <div class="infoLine">
          <div class="infoLine_label">时间段：</div>
          <div class="infoLine_value">{{ eventData.time_interval }}</div>
        </div>
        <div class="infoLine">
          <div class="infoLine_label">教室名称：</div>
          <div class="infoLine_value">{{ eventData.classroom || '-' }}</div>
        </div>
        <div class="infoLine">
          <div class="infoLine_label">地点：</div>
          <div class="infoLine_value">{{ eventData.address || '-' }}</div>
        </div>
        <div class="infoLine">
          <div class="infoLine_label">备注：</div>
          <div class="infoLine_value">{{ eventData.remark || '-' }}</div>
        </div>
      </div>
      <div class="card_Btn">
        <el-button
          type="primary"
          size="mini"
          @click="onEdit"
          v-if="ifOperationButton('arrangingTableItemEdit')"
        >
          编辑
        </el-button>
        <el-button
          type="danger"
          size="mini"
          @click="onDel"
          v-if="ifOperationButton('arrangingTableItemDel')"
        >
          删除
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eventData: {
      type: Object,
      default: () => {},
    },
    cardClass: {
      type: String,
      default: '',
    },
    showCard: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {
    // 编辑
    onEdit() {
      this.$bus_unique.emit('arrangingTableShowAddOrEdit', this.showCard)
    },
    // 删除
    onDel() {
      this.$bus_unique.emit('arrangingTableShowDel', this.showCard)
    },

    // 权限按钮判断
    ifOperationButton(authenKey) {
      const getters = this.$store.getters
      const getAuthenIsPermitted = getters.getAuthenIsPermitted
      const isGetAuthen = getAuthenIsPermitted(authenKey)
      // console.log('isGetAuthen', isGetAuthen)
      return isGetAuthen
    },
  },
}
</script>

<style lang="scss" scoped>
.event-item {
  cursor: pointer;
  font-size: 12px;
  background-color: #62aef3;
  margin-bottom: 6px;
  color: #fff;
  padding: 4px;
  height: auto;
  line-height: 26px;
  position: relative;
  border-radius: 4px;

  &:hover {
    background: #084f91 !important;
    color: #fff !important;
  }
  &.selected {
    background: #0c75d5 !important;

    .info {
      color: #fff;
      font-weight: normal;
    }
    // background-color: #5272FF !important;
    .icon {
      background-color: transparent !important;
    }
  }

  .info {
    text-align: center;
    font-size: 14px;
  }
  #card {
    cursor: initial;
    position: absolute;
    z-index: 999;
    min-width: 250px;
    height: auto;
    left: 50%;
    top: calc(100% + 10px);
    transform: translate(-50%, 0);
    min-height: 100px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    color: #333333;

    &.left-card {
      left: 0;
      transform: translate(0, 0);
    }
    &.right-card {
      right: 0;
      left: auto;
      transform: translate(0, 0);
    }
    &.bottom-card {
      top: auto;
      bottom: calc(100% + 10px);
    }

    .card_info {
      padding: 24px;
      padding-bottom: 10px;

      .infoLine {
        display: flex;
        font-size: 14px;
        margin-bottom: 6px;

        .infoLine_label {
          color: #475774;
          flex-shrink: 0;
        }
        .infoLine_value {
          color: #333333;
        }
      }
    }
    .card_Btn {
      background-color: #e7e7e7;
      text-align: right;
      padding: 10px 20px;
    }
  }
}
</style>

<!--
 * @Date         : 2020-11-30 15:43:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-05-28 22:02:15
 * @FilePath: /src/views/goods/skuModulesV2.vue
-->
<template>
  <div>
    <template v-if="isLoadedAdminDetails && !readonly">
      <mainContentDiv
        class="mb-10 normal-info-modules"
        v-for="(item, index) in propsListModules"
        :key="index"
      >
        <div class="d-flex align-items-center">
          <div class="body-title">{{ item.title }}</div>
          <div
            v-for="(itemLabelBtn, indexLabelBtn) in item.labelBtns"
            :key="indexLabelBtn"
          >
            <div
              v-if="itemLabelBtn.isRender"
              class="body-label"
              @click="handleSelectAttrByDialog(itemLabelBtn, item)"
            >
              {{ itemLabelBtn.title }}
            </div>
          </div>
        </div>
        <div class="body-table">
          <div class="col-8 d-flex flex-wrap my-5">
            <div
              class="col-3 my-5"
              v-for="(itemTd, indexTd) in item.list"
              :key="indexTd"
            >
              <div class="mr-10 mb-10">
                <el-input readonly size="small" v-model="itemTd.label">
                  <i
                    slot="suffix"
                    class="cursor-pointer iconfont icon-del"
                    v-if="!itemTd.disableDelete"
                    @click="handleRemoveThisAttrItem(indexTd, index)"
                  ></i>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </mainContentDiv>
    </template>

    <mainContentDiv class="normal-info-modules">
      <div class="d-flex align-items-baseline body-title">
        <span>产品销售规格</span>
        <span class="sub-title" v-if="!readonly"
          >(在标题栏中输入或选择内容可以进行筛选和批量填充)</span
        >
      </div>
      <div class="body-table">
        <el-button
          class="my-10"
          color="#333744"
          v-if="!readonly"
          @click="
            handleFillOtherPropValueByBatch(
              propsListModules,
              otherPropListModules
            )
          "
          >批量填充</el-button
        >
        <div class="py-20">
          <table class="table-box">
            <!-- 表头 -->
            <th
              class="pa-5 th"
              v-for="(item, index) in propsTable.header"
              :key="'header' + index"
            >
              <el-select
                size="small"
                v-model="item.value"
                :placeholder="item.title"
                :disabled="readonly"
              >
                <el-option
                  v-for="(item, index) in item.list"
                  :key="'optionsList' + index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </th>
            <th
              class="pa-5 th"
              v-for="(item, index) in otherPropListModules"
              :key="index"
            >
              <el-input
                clearable
                size="small"
                v-model="item.value"
                :placeholder="item.modulesName"
                :disabled="readonly"
                @change="item.onChange && item.onChange(item)"
              ></el-input>
            </th>

            <!-- 表身 -->
            <tr
              v-for="(item, index) in propsTable.body"
              :key="'propsTablebody' + index"
            >
              <td
                class="px-10 td"
                v-for="(itemTd, indexTd) in item"
                :key="(itemTd.label || '') + indexTd"
                :rowspan="itemTd.rowspan"
              >
                <div>{{ itemTd.label }}</div>
              </td>
              <td
                class="td"
                v-for="(itemOtherProp, indexOtherProp) in otherPropListModules"
                :key="indexOtherProp"
              >
                <el-input
                  size="small"
                  v-model="itemOtherProp.list[index].value"
                  :placeholder="itemOtherProp.list[index].label"
                  :readonly="readonly"
                  @change="itemOtherProp.onChange(itemOtherProp.list[index])"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mainContentDiv>

    <div v-if="!readonly">
      <!-- 弹窗：班级分类 -->
      <centerDialog ref="centerDialog_classfity">
        <div class="classfity-modules">
          <classfityModules ref="classfityModules"></classfityModules>
        </div>
      </centerDialog>

      <!-- 弹窗：年级分类 -->
      <centerDialog ref="centerDialog_grade">
        <div class="classfity-modules">
          <gradeModules ref="gradeModules"></gradeModules>
        </div>
      </centerDialog>

      <!-- 弹窗：规格 -->
      <centerDialog ref="centerDialog_spec">
        <div class="classfity-modules">
          <specModules ref="specModules"></specModules>
        </div>
      </centerDialog>

      <!-- 弹窗：属性 -->
      <centerDialog ref="centerDialog_attr">
        <div class="classfity-modules">
          <attrModules ref="attrModules"></attrModules>
        </div>
      </centerDialog>
    </div>
  </div>
</template>

<script>
import validValue from "@/assets/js/validValue.js";

import classfityModules from "@/views/goods/sku_component/classfityModules.vue";
import gradeModules from "@/views/goods/sku_component/gradeModules.vue";
import specModules from "@/views/goods/sku_component/specModules.vue";
import attrModules from "@/views/goods/sku_component/attrModules.vue";

export default {
  components: {
    classfityModules,
    gradeModules,
    specModules,
    attrModules,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: Number,
      default: false,
    },
  },
  data() {
    return {
      //其他的规格属性
      otherPropListModules: [
        {
          modulesName: "价格",
          required: true,
          value: "",
          list: [],
          props: "price", //price	是	decimal	sku价格
          onChange: (item) => {
            this.validValue.inputMoney(item);
          },
        },
        {
          modulesName: "课时",
          required: true,
          value: "",
          list: [],
          props: "count", //count	是	int	课时/次数
          onChange: (item) => {
            this.validValue.inputMoney(item); //限制输入带小数的课时
          },
        },
      ],

      propsListModules: [
        {
          title: "班级分类",
          labelBtns: [
            {
              title: "选择班级分类",
              isRender: true,
              authenKey: "goods_classCategory_list",
              refKey_dialog: "centerDialog_classfity",
              refKey_dialogBody: "classfityModules",
            },
          ],
          list: [
            //测试
            // {
            //   label: "在家学",
            //   value: 1,
            // },
          ],
          props: "classify_id", //classify_id	是	id	班级分类id
          props_name: "classify_name", //classify_name	是	id	班级分类名称
        },
        {
          title: "选择年级",
          labelBtns: [
            {
              title: "选择年级",
              isRender: true,
              authenKey: "goods_grade_list",
              refKey_dialog: "centerDialog_grade",
              refKey_dialogBody: "gradeModules",
            },
          ],
          list: [
            //测试
            // {
            //   label: "一年级",
            //   value: 4,
            // },
          ],
          props: "grade_id", //grade_id	是	int	产品年级id
          props_name: "grade_name", //grade_name	是	int	产品年级名称
        },
        {
          title: "选择规格",
          labelBtns: [
            {
              title: "选择规格",
              isRender: true,
              authenKey: "goods_attr_list",
              refKey_dialog: "centerDialog_spec",
              refKey_dialogBody: "specModules",
            },
          ],
          list: [
            //测试
            // {
            //   label: "语",
            //   value: 1,
            // },
          ],
          props: "spec_id", //spec_id	是	int	规格id
          props_name: "spec_name", //spec_name	是	int	规格名称
        },
        {
          title: "选择属性",
          labelBtns: [
            {
              title: "选择属性",
              isRender: true,
              authenKey: "goods_belong_list",
              refKey_dialog: "centerDialog_attr",
              refKey_dialogBody: "attrModules",
            },
          ],

          list: [
            //测试
            // {
            //   label: "月",
            //   value: 1,
            // },
          ],
          props: "attr_id", //attr_id	是	int	属性id
          props_name: "attr_name", //attr_name	是	int	属性名称
        },
      ],
    };
  },
  watch: {
    pageType(newValue) {
      if (
        newValue === 2 &&
        !this.otherPropListModules.find((item) => item.props === "id")
      ) {
        this.otherPropListModules.push({
          modulesName: "sku序列号",
          required: true,
          value: "",
          list: [],
          props: "id", //id	是	int	课时/次数
        });
      }
    },
  },
  mounted() {
    this.validValue = validValue;
  },
  methods: {
    //填充sku
    fillSkuList(skuList) {
      const propsListModules = this.propsListModules;

      // skuList.forEach((itemSku) => {
      //   propsListModules.forEach((item) => {
      //     const { props, props_name, list } = item;
      //     const currentSkuID = itemSku[props];
      //     const existedPropsIndex = list.findIndex(
      //       (item) => item.value === currentSkuID
      //     );
      //     console.info('currentSkuID', currentSkuID);

      //     if (existedPropsIndex === -1) {
      //       list.push({
      //         label: itemSku[props_name],
      //         value: currentSkuID,
      //       });
      //     }
      //   });
      // });

      skuList.forEach((itemSku) => {
        propsListModules.forEach((item) => {
          const { props, props_name, list } = item;
          const currentSkuID = itemSku[props];
          const existedPropsObj = list.find(
            (item) => item.value === currentSkuID
          );

          //注意：该产品被关联了，不可删除
          const disableDelete = itemSku.checked === 1;

          if (!existedPropsObj) {
            const categoryItem = {
              label: itemSku[props_name],
              value: currentSkuID,
            };

            categoryItem.disableDelete = disableDelete;

            list.push(categoryItem);
          } else {
            if (disableDelete && !existedPropsObj.disableDelete) {
              existedPropsObj.disableDelete = true;
            }
          }
        });
      });

      console.info("fillSkuList-propsListModules", propsListModules);

      //注意：此处必须延迟渲染
      //（因为propsListModules得先渲染出来，然后otherPropListModules根据前者进行填充）
      this.$nextTick(() => {
        const otherPropListModules = this.otherPropListModules;

        otherPropListModules.forEach((item) => {
          const { props, list } = item;

          list.forEach((item) => {
            let _templatePropsObj = { ...item }; //临时变量：用于记录当前item的所有规格
            delete _templatePropsObj.value;

            const thisSkuObj = skuList.find((item) => {
              for (let key in _templatePropsObj) {
                if (item[key] !== _templatePropsObj[key]) {
                  return false;
                }
              }

              return true;
            });

            if (thisSkuObj) {
              item.value = thisSkuObj[props];
            }

            _templatePropsObj = null; //销毁变量，释放缓存
          });
        });

        console.info("otherPropListModules", otherPropListModules);
      });
    },

    //弹窗选择属性
    handleSelectAttrByDialog(thisObj, thisPropsObj) {
      const { title, refKey_dialog, refKey_dialogBody } = thisObj;

      this.$refs[refKey_dialog].render({
        title: title,
        width: "50vw",
        ok: () => {
          let { selectedTrList, $refs } = this.$refs[refKey_dialogBody];

          let { list: existedList } = thisPropsObj;
          let existIDListObj = {};

          existedList.forEach((item) => {
            existIDListObj[item.value] = true;
          });

          if (!selectedTrList || selectedTrList.length <= 0) {
            throw "请至少选择一个";
          }

          //去重赋值
          selectedTrList.forEach((item) => {
            const { name, id } = item;

            if (!existIDListObj[id]) {
              existedList.push({
                label: name,
                value: id,
              });
            }
          });

          $refs.tableList.clearSelection(); //清空table中的多选
        },
      });
    },

    //删除规格
    handleRemoveThisAttrItem(thisIndexInList, thisIndexInAttrList) {
      this.propsListModules[thisIndexInAttrList].list.splice(
        thisIndexInList,
        1
      );
    },

    //批量填充
    handleFillOtherPropValueByBatch(
      salePropTableHeaderList,
      otherPropListModules
    ) {
      // console.info("salePropTableHeaderList", salePropTableHeaderList);
      // console.info("otherPropListModules", otherPropListModules);

      //筛选条件: 属性，规格，分类等
      let commandObj = {};

      salePropTableHeaderList.forEach((item) => {
        const { list, props, value } = item;

        if (value) {
          commandObj[props] = item.value;
          item.value = ""; //清空筛选条件
        }
      });

      otherPropListModules.forEach((item) => {
        const { list, value: toFillValue } = item;

        list.forEach((item) => {
          let equiredAllComamnd = true;

          for (let key in commandObj) {
            if (item[key] !== commandObj[key]) {
              equiredAllComamnd = false;
            }
          }

          //具备所有条件
          if (equiredAllComamnd && toFillValue) {
            item.value = toFillValue; //赋值
          }
        });

        item.value = ""; //清空输入的批量填充值
      });

      commandObj = null; //销毁。释放闭包
    },

    //重新生成其他规格列表
    renderOtherPropList(
      newOtherPropList,
      otherPropListModules,
      toSubmitPropsKeyList
    ) {
      let toSubmitPropsKeyListLen = toSubmitPropsKeyList.length;

      newOtherPropList.forEach((item, index) => {
        item.forEach((itemList, indexList) => {
          const existedOtherProp = otherPropListModules[index].list.find(
            (item) => {
              // 多规格筛选，
              // 等同作用：
              // item.prop0 === itemList.prop0 &&
              // item.prop1 === itemList.prop1 &&
              // item.prop2 === itemList.prop2

              for (let i = 0; i < toSubmitPropsKeyListLen; i++) {
                const currentPropKey = toSubmitPropsKeyList[i];

                if (item[currentPropKey] !== itemList[currentPropKey]) {
                  return false;
                }
              }
              return true;
            }
          );

          //找到已存在的输入信息，直接替换即可
          if (existedOtherProp) {
            // console.info("existedOtherProp", existedOtherProp);
            item[indexList] = existedOtherProp; //注意，这里不能这样写 itemList = existedOtherProp，会导致数据无法替换
          }
        });

        otherPropListModules[index].list = item; //替换掉已存在的其他规格列表
      });
    },

    //获取最终的sku
    getSkuList() {
      const otherPropListModules = this.otherPropListModules;

      let skuList = [];

      let firstRow = otherPropListModules.slice(0, 1);
      let otherRow = otherPropListModules.slice(1, otherPropListModules.length);

      firstRow.map((item) => {
        const { props, list } = item;

        list.forEach((itemFirstRow, indexFirstRow) => {
          const { value } = itemFirstRow;
          const copySkuItem = { ...itemFirstRow }; //拷贝数据

          otherRow.forEach((item) => {
            const { props, list } = item;

            copySkuItem[props] = list[indexFirstRow].value;
          });

          delete copySkuItem.value; //删除多余的value

          copySkuItem[props] = value; //赋值
          skuList.push(copySkuItem); //生成sku
        });
      });

      const skuValKeyList = this.skuValKeyList;
      console.log("skuValKeyList", skuValKeyList);

      //过滤出有输入完全的sku
      console.log("skuList", skuList);
      for (let i = 0; i < skuList.length; i++) {
        console.log("i", skuList[i]);
        skuList[i].count = skuList[i].count + "";

        for (let j of skuValKeyList) {
          if (!skuList[i][j]) throw "请至少输入一个完成的规格";
        }
      }

      return skuList;
    },
  },
  computed: {
    isLoadedAdminDetails() {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isLoadedAdminDetails = getters.isLoadedAdminDetails();

      if (isLoadedAdminDetails) {
        this.propsListModules.forEach((item) =>
          item.labelBtns.forEach((item) => {
            item.isRender = getAuthenIsPermitted(item.authenKey);
          })
        );
      }

      return isLoadedAdminDetails;
    },
    //销售规格表
    propsTable() {
      try {
        let totalRows = 1;
        let tableRowsList = [];

        const propsListModules = this.propsListModules;

        // 筛选出被选中的规格
        let allSelectedPropsList = propsListModules.map((item) => {
          const propList = item.list;

          return propList;
        });

        // 是否存在没有选中的规格
        const isExistedEmptyProps = !!allSelectedPropsList.find(
          (item) => item.length <= 0
        );

        console.info("allSelectedPropsList", allSelectedPropsList);

        if (isExistedEmptyProps === false) {
          allSelectedPropsList.forEach((item) => {
            totalRows *= item.length; //计算出一共需要多少行tr
          });

          for (let i = 0; i < totalRows; i++) {
            tableRowsList.push([]);
          }

          let otherPropListModules = this.otherPropListModules;
          let otherPropListModulesLen = this.otherPropListModules.length;

          let newOtherPropList = []; //建一个新的其他规格表，用来同步记录当前最新的所有的规格信息

          for (let i = 0; i < otherPropListModulesLen; i++) {
            newOtherPropList[i] = [];
          }

          // 思路：
          // 生成个数组tableRowsList

          // 将table看成一整个矩形
          // 遍历第一个规格A，将第一列切成A.length份
          // 遍历第二个规格B，将第二列切成 A.length * B.length份
          // 遍历第三个规格C，将第三列切成 A.length * B.length份 * C.length份
          // ...
          let renderTableBody = (
            tableRowsList, //赋值目标数组
            allSelectedPropsList, //所有规格所在数组
            currenListIndex,
            lastScale, //上一个规格的跨度
            lastPushIndex, //上次赋值的起始index
            parentPropInfo,
            toSubmitPropsKeyList //后端提交的key
          ) => {
            const currentAttrList = allSelectedPropsList[currenListIndex];
            const scale = lastScale / currentAttrList.length;

            //开始切割，并把当前规格的第index位 赋值 到被切出来的位置上
            currentAttrList.forEach((item, index) => {
              const pushIndex = index * scale; //当前数据要要赋值到 allSelectedPropsList 的index
              let currentTableTrList = tableRowsList[pushIndex + lastPushIndex]; //当前的tr行标签列表

              item.rowspan = scale;
              currentTableTrList.push(item); //赋值

              parentPropInfo = {
                ...parentPropInfo,
                [toSubmitPropsKeyList[currenListIndex]]: item.value,
              };

              if (currenListIndex < allSelectedPropsList.length - 1) {
                //开始递归
                renderTableBody(
                  tableRowsList,
                  allSelectedPropsList,
                  currenListIndex + 1,
                  scale,
                  lastPushIndex + pushIndex,
                  parentPropInfo,
                  toSubmitPropsKeyList
                );
              } else {
                //递归结束，记录当前的父级规格
                for (let i = 0; i < otherPropListModulesLen; i++) {
                  newOtherPropList[i].push({
                    ...parentPropInfo, //里面有父级的规格信息 prop0 prop1 prop2 等
                    value: "",
                  });
                }
              }
            });
          };

          let toSubmitPropsKeyList = this.toSubmitPropsKeyList;

          //生成规格表
          renderTableBody(
            tableRowsList,
            allSelectedPropsList,
            0,
            totalRows,
            0,
            {},
            toSubmitPropsKeyList
          );

          //生成其他的规格表， 价格库存等
          this.renderOtherPropList(
            newOtherPropList,
            otherPropListModules,
            toSubmitPropsKeyList
          );

          console.info("tableRowsList", tableRowsList);
          console.info("otherPropListModules", otherPropListModules);
        }

        return {
          header: propsListModules,
          body: tableRowsList,
        };
      } catch (error) {
        console.error("error", error);
      }
    },

    //预提交的规格key(选出来的那一部分)
    toSubmitPropsKeyList() {
      const propsListModules = this.propsListModules;

      return propsListModules.map((item) => item.props);
    },

    //输入的部分
    skuValKeyList() {
      const skuValKeyList = this.otherPropListModules.map((item) => item.props);

      return skuValKeyList;
    },
  },
};
</script>

<style lang="scss" scoped>
// 模块：普通信息
.normal-info-modules {
  .body-title {
    padding: 0.85rem 0.5rem 0.85rem 1.25rem;
    font-size: 0.8rem;
    color: #606060;

    .sub-title {
      font-size: 0.7rem;
      color: #606060;
    }
  }

  .body-label {
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    background-color: #333744;
    border-radius: 0.2rem;
    border: solid 0.05rem #cccccc;
    line-height: 1.5rem;
    font-size: 0.7rem;
    color: #ffffff;
  }

  .body-table {
    border-top: 1px solid #eaeaea;
    padding-left: 1.15rem;

    .table-box {
      border-collapse: collapse;

      .th {
        background-color: #f0f2f5;
        border: solid 1px #d9d9d9;
      }

      .td {
        background-color: #ffffff;
        border: solid 1px #d9d9d9;
        font-size: 0.7rem;
        color: #333;

        ::v-deep .el-input__inner {
          border: none;
          font-size: 0.7rem;
        }
      }
    }

    .icon-del {
      width: initial;
      height: initial;
      line-height: 32px;
    }
  }
}

.classfity-modules {
  height: 40vh;
}

::v-deep .el-button {
  line-height: 1.5rem;
  padding: 0 0.5rem;
  background-color: #333744;
  border-radius: 0.2rem;
  font-size: 0.7rem;
  color: #ffffff;
}
</style>

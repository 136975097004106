<!--
 * @Date         : 2020-11-26 16:22:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-31 10:50:16
 * @FilePath     : \leXue_manage_pc\src\views\customer\studentDetails.vue
-->
<template>
  <div class="h-100 d-flex flex-column">
    <div>
      <!-- 模块：导航 -->
      <div class="d-flex flex-wrap align-items-center category-list-modules">
        <div
          class="mx-10 px-5 cursor-pointer categroy-item"
          v-for="(item, index) in studentNavList"
          :key="index"
          :class="item.id === currentStudentID ? 'active' : ''"
          @click="handleSwitchCurrentStudent(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="col overflow-scroll">
      <div>
        <!-- 模块：普通信息 -->
        <mainContentDiv
          class="my-10 normal-info-modules"
          v-for="(item, index) in baseInfoObj"
          :key="index"
        >
          <template v-if="item.list">
            <div class="d-flex align-items-center title">
              <div>
                {{ item.title }}
              </div>
              <div
                class="d-flex align-items-center ml-20"
                v-if="item.buttonList"
              >
                <el-button
                  size="mini"
                  v-for="(itemBtn, indexBtn) in item.buttonList"
                  :key="indexBtn"
                  :type="item.currentBtnIndex === indexBtn ? 'primary' : ''"
                  @click="item.onTapBtn(itemBtn, indexBtn, item)"
                  >{{ itemBtn.title || "--" }}</el-button
                >
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="d-flex flex-wrap mt-10 body-table">
                <template v-for="(itemJ, indexJ) in item.list">
                  <div class="col-3 my-10" :key="indexJ" v-if="!itemJ.show">
                    <div class="td">
                      <div class="label">{{ itemJ.label }}</div>
                      <div class="value">{{ itemJ.value || "--" }}</div>
                    </div>
                  </div>
                </template>
              </div>
              <!-- 编辑按钮 -->
              <div style="margin-left: 10px; margin-bottom: 10px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="editBaseInfoObj(index, item.list[0].value)"
                  v-if="
                    ifOperationButton(
                      index === 'student' ? 'student_edit' : 'parent_edit'
                    )
                  "
                >
                  编辑{{ index === "student" ? "学生" : "家长" }}信息
                </el-button>
              </div>
            </div>
          </template>
        </mainContentDiv>

        <!-- 班级信息 -->
        <mainContentDiv class="my-10 normal-info-modules">
          <div class="d-flex align-items-center title">班级信息</div>
          <div class="pa-20">
            <el-form
              inline
              class="classInfoForm"
              :model="classInfoForm"
              ref="classInfoFormRef"
              label-width="auto"
              label-position="top"
              size="mini"
              :disabled="!ifOperationButton('save_archives')"
            >
              <el-form-item label="带班老师：" prop="teacher_id">
                <el-select
                  v-model="classInfoForm.teacher_id"
                  placeholder="请选择带班老师"
                  filterable
                >
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in teacherList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="课程主题：" prop="course_id">
                <el-select
                  v-model="classInfoForm.course_id"
                  placeholder="请选择课程主题"
                  filterable
                  multiple
                  v-if="studentInfoRes"
                >
                  <el-option
                    :label="item.name"
                    :value="item.id + ''"
                    v-for="item in studentInfoRes.archives_course"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="lineForm_r">
              <el-button
                type="primary"
                size="mini"
                @click="classInfoFormSave"
                v-if="ifOperationButton('save_archives')"
              >
                保存
              </el-button>
            </div>
          </div>
        </mainContentDiv>

        <!-- 模块：班级信息 -->
        <mainContentDiv class="my-10 nav-info-modules" v-if="currentStudentID">
          <div class="d-flex align-items-center nav-list">
            <div
              class="py-15 mx-20 nav-item cursor-pointer"
              v-for="(item, index) in classInfoModules.navList"
              :key="index"
              :class="{
                active: item.type === classInfoModules.currentNavType,
              }"
              @click="handleChangeStudentClassInfo(item, classInfoModules)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="body">
            <tableList
              class="pa-20"
              ref="tableList_classInfo"
              v-if="classInfoModules.body"
              :initOptions="classInfoModules.body"
            ></tableList>
          </div>
        </mainContentDiv>

        <!-- 模块：购买/退款 -->
        <mainContentDiv class="my-10 nav-info-modules" v-if="currentStudentID">
          <div class="d-flex align-items-center nav-list">
            <div
              class="py-15 mx-20 nav-item cursor-pointer"
              v-for="(item, index) in monneyRecordModules.navList"
              :key="index"
              :class="{
                active: item.type === monneyRecordModules.currentNavType,
              }"
              @click="handleChangeStudentClassInfo(item, monneyRecordModules)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="body">
            <tableList
              class="pa-20"
              ref="tableList_classInfo"
              v-if="monneyRecordModules.body"
              :initOptions="monneyRecordModules.body"
            ></tableList>
          </div>
        </mainContentDiv>

        <!-- 已参加过项目登记 -->
        <mainContentDiv class="my-10 normal-info-modules">
          <div class="d-flex align-items-center title">已参加过项目登记</div>
          <div class="pa-20">
            <el-form
              inline
              class="projectRegistrationForm"
              :model="projectRegistrationForm"
              ref="projectRegistrationFormRef"
              label-width="auto"
              label-position="top"
              size="mini"
              :disabled="!ifOperationButton('save_archives')"
            >
              <el-form-item label="曹灿杯：" prop="ccb">
                <el-input
                  v-model="projectRegistrationForm.ccb"
                  placeholder="请输入曹灿杯"
                ></el-input>
              </el-form-item>
              <el-form-item label="朗诵考级：" prop="lskj">
                <el-input
                  v-model="projectRegistrationForm.lskj"
                  placeholder="请输入朗诵考级"
                ></el-input>
              </el-form-item>
              <el-form-item label="新春雅集（年份+类型）：" prop="xcyj">
                <el-input
                  v-model="projectRegistrationForm.xcyj"
                  placeholder="请输入新春雅集（年份+类型）"
                ></el-input>
              </el-form-item>
              <el-form-item label="少儿春晚（年份+类型）：" prop="secw">
                <el-input
                  v-model="projectRegistrationForm.secw"
                  placeholder="请输入少儿春晚（年份+类型）"
                ></el-input>
              </el-form-item>
              <el-form-item label="艺术盛典（年份+类型）：" prop="yssd">
                <el-input
                  v-model="projectRegistrationForm.yssd"
                  placeholder="请输入艺术盛典（年份+类型）"
                ></el-input>
              </el-form-item>
              <el-form-item label="声形特训：" prop="sxtx">
                <el-input
                  v-model="projectRegistrationForm.sxtx"
                  placeholder="请输入声形特训"
                ></el-input>
              </el-form-item>
              <el-form-item label="时间管理特训：" prop="sjgltx">
                <el-input
                  v-model="projectRegistrationForm.sjgltx"
                  placeholder="请输入时间管理特训"
                ></el-input>
              </el-form-item>
              <el-form-item label="思维导图特训：" prop="swdttx">
                <el-input
                  v-model="projectRegistrationForm.swdttx"
                  placeholder="请输入思维导图特训"
                ></el-input>
              </el-form-item>
              <el-form-item label="研学活动：" prop="yxhd">
                <el-input
                  v-model="projectRegistrationForm.yxhd"
                  placeholder="请输入研学活动"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="lineForm_r">
              <el-button
                type="primary"
                size="mini"
                @click="projectRegistrationFormSave"
                v-if="ifOperationButton('save_archives')"
              >
                保存
              </el-button>
            </div>
          </div>
        </mainContentDiv>

        <!-- 拓展信息 -->
        <mainContentDiv class="my-10 normal-info-modules">
          <div class="d-flex align-items-center title">拓展信息</div>
          <div class="pa-20">
            <el-form
              inline
              class="expandInfoForm"
              :model="expandInfoForm"
              ref="expandInfoFormRef"
              label-width="auto"
              label-position="top"
              size="mini"
              :disabled="!ifOperationButton('save_archives')"
            >
              <el-form-item label="是否喜欢本课程：" prop="xsyyd" class="w-100">
                <el-radio-group
                  v-model="expandInfoForm.xsyyd"
                  v-if="studentInfoRes"
                >
                  <el-radio
                    :label="item.id + ''"
                    v-for="item in studentInfoRes.archives_xsyyd"
                    :key="item.id"
                    >{{ item.name }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="课堂活跃度：" prop="kthyd" class="w-100">
                <el-radio-group
                  v-model="expandInfoForm.kthyd"
                  v-if="studentInfoRes"
                >
                  <el-radio
                    :label="item.id + ''"
                    v-for="item in studentInfoRes.archives_kthyd"
                    :key="item.id"
                    >{{ item.name }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="个人性格特点：" prop="grxgtd" class="w-100">
                <el-checkbox-group
                  v-model="expandInfoForm.grxgtd"
                  v-if="studentInfoRes"
                >
                  <el-checkbox
                    :label="item.id + ''"
                    v-for="item in studentInfoRes.archives_grxgtd"
                    :key="item.id"
                    >{{ item.name }}</el-checkbox
                  >
                  <el-checkbox label="0">
                    其他
                    <el-input
                      v-if="
                        expandInfoForm.grxgtd.length &&
                          expandInfoForm.grxgtd.indexOf('0') > 0
                      "
                      v-model="expandInfoForm.grxgtd_qt"
                      placeholder="请输入"
                    ></el-input>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="课后作业完成情况：" prop="khzywcqk">
                <el-input
                  v-model="expandInfoForm.khzywcqk"
                  placeholder="请输入课后作业完成情况"
                ></el-input>
              </el-form-item>
              <el-form-item label="声形特点：" prop="sxtd">
                <el-input
                  v-model="expandInfoForm.sxtd"
                  placeholder="请输入声形特点"
                ></el-input>
              </el-form-item>
              <el-form-item label="参与广播站情况：" prop="cygbzqk">
                <el-input
                  v-model="expandInfoForm.cygbzqk"
                  placeholder="请输入参与广播站情况"
                ></el-input>
              </el-form-item>
              <el-form-item label="参与校园TV情况：" prop="cyxytvqk">
                <el-input
                  v-model="expandInfoForm.cyxytvqk"
                  placeholder="请输入参与校园TV情况"
                ></el-input>
              </el-form-item>
              <el-form-item label="获奖经历：" prop="hjjl">
                <el-input
                  v-model="expandInfoForm.hjjl"
                  placeholder="请输入获奖经历"
                ></el-input>
              </el-form-item>
              <el-form-item label="学校职位：" prop="xxzw">
                <el-input
                  v-model="expandInfoForm.xxzw"
                  placeholder="请输入学校职位"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="家长需求与意愿度："
                prop="jzxqyyyd"
                class="w-100"
              >
                <el-input
                  v-model="expandInfoForm.jzxqyyyd"
                  placeholder="请输入家长需求与意愿度"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="lineForm_r">
              <el-button
                type="primary"
                size="mini"
                @click="expandInfoFormSave"
                v-if="ifOperationButton('save_archives')"
              >
                保存
              </el-button>
            </div>
          </div>
        </mainContentDiv>

        <!-- 星级学员 -->
        <mainContentDiv class="my-10 normal-info-modules">
          <div class="d-flex align-items-center title" v-if="studentInfoRes">
            星级学员：{{ studentInfoRes.member_info.member_level }}
          </div>
          <div class="pa-20">
            <el-form
              inline
              class="internationalStudentsForm"
              :model="internationalStudentsForm"
              ref="internationalStudentsFormRef"
              label-width="auto"
              label-position="top"
              size="mini"
              :disabled="!ifOperationButton('save_archives')"
            >
              <el-form-item label="成长规划意见：" prop="czghyj" class="w-100">
                <el-input
                  v-model="internationalStudentsForm.czghyj"
                  placeholder="请输入成长规划意见"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="lineForm_r">
              <el-button
                type="primary"
                size="mini"
                @click="internationalStudentsFormSave"
                v-if="ifOperationButton('save_archives')"
              >
                保存
              </el-button>
            </div>
          </div>
        </mainContentDiv>
      </div>
    </div>

    <!-- 模块：底部操作按钮 -->
    <footerBtnModules :btnList="curentPageFooterBtnList" />

    <!-- 弹窗：历史跟进客服 -->
    <centerDialog ref="centerDialog_historyService">
      <div style="height: 40vh">
        <parentHistoryServicerList
          :initOptions="initOptions_servicer"
        ></parentHistoryServicerList>
      </div>
    </centerDialog>

    <!-- 弹窗：原存费记录列表 -->
    <centerDialog ref="centerDialog_historyYCf">
      <div style="height: 40vh">
        <historyYCfList :initOptions="initOptions_historyYCf"></historyYCfList>
      </div>
    </centerDialog>

    <!-- 新增/编辑学生 -->
    <addOrEditStudent ref="addOrEditStudent"></addOrEditStudent>

    <!-- 新增/编辑家长 -->
    <addOrEditParent ref="addOrEditParent"></addOrEditParent>
  </div>
</template>

<script>
import footerBtnModules from "@/components/footerBtnModules.vue";
import parentHistoryServicerList from "@/views/customer/parentHistoryServicerList.vue";
import historyYCfList from "./historyYCfList.vue";
import addOrEditStudent from "./addOrEditStudent.vue";
import addOrEditParent from "./addOrEditParent.vue";
import { saveAs } from "file-saver";
import appConfig from "@/assets/js/appConfig.js";

export default {
  components: {
    footerBtnModules,
    parentHistoryServicerList,
    historyYCfList,
    addOrEditStudent,
    addOrEditParent,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: [Number, String],
      default: null,
    },
    studentID: {
      type: [Number, String],
      default: null,
    },
    parentID: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      baseInfoObj: {
        student: {
          title: "学生信息",
          list: null,
        },
        parent: {
          title: "家长信息",
          list: null,
          currentBtnIndex: 0,
          onTapBtn: (thisBtn, thisBtnIndex, thisBtnParent) => {
            const { currentBtnIndex } = thisBtnParent;

            if (currentBtnIndex === thisBtnIndex) {
              console.info("点击的是同一个", "");
              return false;
            }

            thisBtnParent.list = this.inputParentInfo(thisBtn.dataObj);
            thisBtnParent.currentBtnIndex = thisBtnIndex;
          },
        },
      },
      studentNavList: [],
      //班级信息
      classInfoModules: {
        navList: [
          {
            title: "在读班级",
            type: 1,
            onTap: (thisObj, classInfoModules) => {
              classInfoModules.body = null; //先销毁table对象

              this.$nextTick(() => {
                classInfoModules.body = this.getTableOptions_class();
              });
            },
          },
          {
            title: "历史班级记录",
            type: 2,
            onTap: (thisObj, classInfoModules) => {
              classInfoModules.body = null; //先销毁table对象

              this.$nextTick(() => {
                classInfoModules.body = this.getTableOptions_class();
              });
            },
          },
          {
            title: "核销课时记录",
            type: 3,
            onTap: (thisObj, classInfoModules) => {
              classInfoModules.body = null; //先销毁table对象

              this.$nextTick(() => {
                let countListConfign = this.countListConfign;

                if (!countListConfign) {
                  countListConfign = this.countListConfign = this.getTableOptions_gotClass(); //获取课程核销记录
                }

                classInfoModules.body = countListConfign;
              });
            },
          },
        ],

        currentNavType: 1,
        body: null,
      },
      // 开支记录
      monneyRecordModules: {
        navList: [
          {
            title: "购买记录",
            type: 1,
            onTap: (thisObj, monneyRecordModules) => {
              monneyRecordModules.body = null; //先销毁table对象

              this.$nextTick(() => {
                monneyRecordModules.body = this.getTableOptions_buyRecord();
              });
            },
          },
          {
            title: "退款记录",
            type: 2,
            onTap: (thisObj, monneyRecordModules) => {
              monneyRecordModules.body = null; //先销毁table对象

              this.$nextTick(() => {
                monneyRecordModules.body = this.getTableOptions_refundRecord();
              });
            },
          },
        ],
        currentNavType: 1,
        body: null,
      },
      currentStudentID: null,
      currentParentID: null,
      initOptions_servicer: null,
      initOptions_historyYCf: null,
      parentInfoRes: null,
      studentInfoRes: null,
      //已参加过项目登记
      projectRegistrationForm: {
        ccb: "",
        lskj: "",
        xcyj: "",
        secw: "",
        yssd: "",
        sxtx: "",
        sjgltx: "",
        swdttx: "",
        yxhd: "",
      },
      // 拓展信息
      expandInfoForm: {
        xsyyd: "",
        kthyd: "",
        grxgtd: "",
        khzywcqk: "",
        sxtd: "",
        cygbzqk: "",
        cyxytvqk: "",
        hjjl: "",
        xxzw: "",
        jzxqyyyd: "",
        grxgtd_qt: "",
      },
      // 星级学员
      internationalStudentsForm: {
        czghyj: "",
      },
      classInfoForm: {
        teacher_id: "",
        course_id: [],
      },
      teacherList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.getteacherList();

        let _studentID,
          _parentID,
          _pageType = null;

        console.log("!this.dialog", this.dialog);
        if (!this.dialog) {
          let { studentID, parentID, pageType } = this.$route.query;
          _studentID = studentID;
          _parentID = parentID;
          _pageType = Number(pageType);
        } else {
          _studentID = this.studentID;
          _parentID = this.parentID;
          _pageType = this.pageType;
        }

        console.log("_pageType", _pageType);

        switch (
          _pageType // 1：学生详情 2：家长详情
        ) {
          case 1:
            if (!_studentID) {
              throw "参数缺失：id";
            }

            //获取学生详情
            const {
              name,
              parent_info,
            } = await this.getAndSetStudentDetailsByID(_studentID);
            const { parent } = this.baseInfoObj;

            //一个学生可以有多个家长
            parent.buttonList = parent_info.map((item) => {
              return {
                title: item.name,
                id: item.id,
                dataObj: item,
              };
            });

            parent.list = this.inputParentInfo(parent_info[0]);

            // console.log('parent_info', parent_info)
            // 保存接口家长信息
            this.parentInfoRes = parent_info;

            //顶部的导航，只有当前这个学生
            this.studentNavList.push({
              title: name,
            });

            break;
          case 2:
            if (!_parentID) {
              throw "参数缺失：id";
            }

            //获取家长详情
            const res = await this.getAndSetParentDetailsByID(_parentID);
            console.log("家长信息", res);
            // 保存接口家长信息
            this.parentInfoRes = [res];

            //生成学生导航
            if (res.children.length > 0) {
              const currentStudentID = res.children[0].id;

              this.studentNavList = res.children.map((item) => {
                return {
                  title: item.name,
                  id: item.id,
                };
              });

              _studentID = currentStudentID;

              //获取学生详情
              this.getAndSetStudentDetailsByID(currentStudentID);
            }

            break;
        }

        //保存当前家长/学生ID
        if (_studentID) {
          this.currentStudentID = Number(_studentID);
        }
        if (_parentID) {
          this.currentParentID = Number(_parentID);
        }

      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取学生详情
    async getAndSetStudentDetailsByID(id) {
      const lastStudentID = this.currentStudentID;

      try {
        if (lastStudentID === id) {
          return false;
        }

        this.currentStudentID = id;

        const { res } = await this.$ajax({
          apiKey: "getStudentDetails",
          data: {
            id: id,
          },
        });

        const studentInfo = (this.studentInfo = res[0]);
        // console.log('studentInfo', studentInfo)
        // 保存接口学生信息
        this.studentInfoRes = studentInfo;

        this.projectRegistrationForm = studentInfo.attended_item;

        this.expandInfoForm = studentInfo.expand_info;
        if (!this.expandInfoForm.grxgtd) {
          this.expandInfoForm.grxgtd = [];
        } else {
          this.expandInfoForm.grxgtd = JSON.parse(this.expandInfoForm.grxgtd);
        }

        this.internationalStudentsForm = studentInfo.member_info;

        this.classInfoForm = studentInfo.course_teacher;
        if (!this.classInfoForm.teacher_id && studentInfo.class_info.length) {
          this.classInfoForm.teacher_id = Number(studentInfo.class_info[0].teacher_id)
        }
        if (this.classInfoForm.teacher_id) {
         this.classInfoForm.teacher_id = Number(this.classInfoForm.teacher_id)
        }
         if (this.classInfoForm.course_id) {
          this.classInfoForm.course_id = this.classInfoForm.course_id.split(
            ","
          );
        }

        //填充学生信息
        this.baseInfoObj.student.list = this.inputStudentInfo(studentInfo);

        //生成班级/购买信息
        this.renderClassOrBuyInfo(this.classInfoModules); //在读/历史班级等信息
        this.renderClassOrBuyInfo(this.monneyRecordModules); //购买/退款记录等信息

        return studentInfo;
      } catch (error) {
        this.currentStudentID = lastStudentID;
        this.$catchError(error);
      }
    },

    //生成班级/购买信息
    renderClassOrBuyInfo(infoModulse) {
      //购买记录
      const { navList, currentNavType } = infoModulse;
      const currentClassInfo = navList.find(
        (item) => item.type === currentNavType
      );

      currentClassInfo.onTap(currentClassInfo, infoModulse);
    },

    //切换当前学生
    handleSwitchCurrentStudent(thisStudent) {
      const { id } = thisStudent;

      id && this.getAndSetStudentDetailsByID(id); //获取学生详情
    },

    //获取家长详情
    async getAndSetParentDetailsByID(id) {
      try {
        const { res } = await this.$ajax({
          apiKey: "getParentDetails",
          data: {
            id, //id	是	int	家长id
          },
        });

        const parentInfo = res;

        //填充家长信息
        this.baseInfoObj.parent.list = this.inputParentInfo(parentInfo);

        return parentInfo;
      } catch (error) {
        this.$catchError(error);
      }
    },

    //获取班级学生配置表
    getTableOptions_class() {
      let getTableOptions = {
        headers: [
          { label: "班级名称", props: "name" },
          { label: "开班时间", props: "start_time" },
          { label: "结班时间", props: "end_time" },
          { label: "上课地点", props: "address" },
          { label: "班主任", props: "teacher_name" },
          { label: "总课时", props: "count" },
          { label: "剩余课时", props: "surplus_count" },
          { label: "已核销课时", props: "clock_count_sum" },
          { label: "课时总额", props: "price" },
          { label: "核销金额", props: "attend_price" },
          { label: "余额", props: "surplus_price" },
          { label: "备注", props: "remark" },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "cyan",
            onTap: (options) => {
              this.$router.push({
                name: "classDetails",
                query: {
                  pageType: 2,
                  id: options.cid,
                },
              });
            },
          },
        ],
        hidePaginate: true,
        getData: async (options) => {
          let dataList;

          const studentInfo = this.studentInfo;
          const classType = this.classInfoModules.currentNavType;

          if (classType === 1) {
            dataList = studentInfo.class_info;
          } else if (classType === 2) {
            dataList = studentInfo.old_class_info;
          }

          return {
            code: 1,
            res: {
              data: dataList,
              last_page: 1,
              current_page: 1,
              total: 50,
              per_page: 50,
            },
          };
        },
      };

      return getTableOptions;
    },

    //获取课程核销记录
    getTableOptions_gotClass() {
      let getTableOptions = {
        authenKey: "class_clockCountList",
        headers: [
          { label: "课程名称", props: "pro_name" },
          { label: "班主任", props: "teacher_name" },
          { label: "课程类型", props: "type_name" },
          { label: "年级", props: "grade_name" },
          { label: "上课地点", props: "address" },
          { label: "核销课时", props: "clock_count" },
          { label: "核销时间", props: "created_at" },
        ],
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getClockCountList",
            data: {
              ...options,
              sid: this.currentStudentID,
            },
          });

          return ret;
        },
      };

      return getTableOptions;
    },

    //获取购买记录
    getTableOptions_buyRecord() {
      let getTableOptions = {
        authenKey: "order_list",
        headers: [
          { label: "订单编号", props: "order_id" },
          { label: "学生名称", props: "student_name" },
          { label: "家长名称", props: "_parentName" },
          { label: "购买的产品", props: "_buyyedProduct" },
          { label: "购买的产品规格", props: "_specificationDetails" },
          { label: "订单类型", props: "order_type_str" },
        ],
        actionBtns: [
          {
            title: "查看",
            color: "cyan",
            authenKey: "order_details",
            onTap: (options) => {
              this.$router.push({
                name: "addOrEditOrder",
                query: {
                  pageType: 2,
                  id: options.oid,
                  studentID: options.sid,
                },
              });
            },
          },
          {
            title: "退款",
            color: "red",
            authenKey: "order_refund",
            onTap: async (options) => {
              this.$router.push({
                name: "addOrEditOrder",
                query: {
                  pageType: 3,
                  id: options.oid,
                  studentID: options.sid,
                },
              });
            },
          },
          {
            title: "删除",
            color: "red",
            authenKey: "order_del",
            onDelete: async (options) => {
              return await this.$ajax({
                apiKey: "delOrder",
                data: {
                  oid: options.oid, //oid	是	int	订单id
                },
              });
            },
          },
        ],
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getOrderList",
            data: {
              ...options,
              sid: this.currentStudentID,
            },
          });

          return {
            code: ret.code,
            res: ret.res.list,
          };
        },
      };

      return getTableOptions;
    },

    //获取退款记录
    getTableOptions_refundRecord() {
      let getTableOptions = {
        authenKey: "order_refundRecordList",
        headers: [
          //           "id": 14,
          // "refund_oid": "2020120109300007",
          // "oid": 2,
          // "op_id": 2,
          // "refund_date": "2020-12-10",
          // "refund_reason": "我问问",
          // "admin_id": 17,
          // "refund_count": 2,
          // "refund_price": "200.00",
          // "admin_name": "zb",
          // "class_name": "乐学小记者2班",
          // "teacher_id": "2",
          // "is_course": 0,
          // "pro_name": "乐学小记者1",
          // "type_name": "兴趣类",
          // "classify_name": "在家学",
          // "grade_name": "小学1-6年级",
          // "spec_name": "语",
          // "attr_name": "季",
          // "student_name": "娜可露露",
          // "parent_name": "李四",
          // "teacher_name": "test"

          { label: "产品", props: "pro_name" },
          { label: "退款单号", props: "refund_oid" },
          { label: "退款日期", props: "refund_date" },
          { label: "退款金额", props: "refund_price" },
          { label: "班主任", props: "teacher_name" },
        ],
        getData: async (options) => {
          let ret = await this.$ajax({
            apiKey: "getRefundOrderList",
            data: {
              ...options,
              sid: this.currentStudentID,
            },
          });

          return {
            code: ret.code,
            res: ret.res.list,
          };
        },
      };

      return getTableOptions;
    },

    //切换学生班级信息
    handleChangeStudentClassInfo(thisObj, classInfoModules) {
      const { type, onTap } = thisObj;

      classInfoModules.currentNavType = type;
      onTap && onTap(thisObj, classInfoModules);
    },

    //填充学生信息
    inputStudentInfo(res) {
      return [
        {
          label: "id",
          value: res.id,
          show: true,
        },
        {
          label: "姓名",
          value: res.name,
        },
        {
          label: "出生日期",
          value: res.birth,
        },
        {
          label: "性别",
          value: ["保密", "男", "女"][res.gender],
        },
        {
          label: "身份证号码",
          value: res.card,
        },
        {
          label: "户籍",
          value: res.city,
        },
        {
          label: "所属学校",
          value: res.s_name,
        },
        {
          label: "所属校区",
          value: res.xq_name,
        },
        {
          label: "年级",
          value: res.grade,
        },
        {
          label: "入读时间",
          value: res.enrollment_time,
        },
        {
          label: "兴趣特长（艺术类）",
          value: res.interest,
        },
        // {
        //   label: "与家长关系",
        //   value: res.relation_name,
        // },
        {
          label: "备注",
          value: res.remark,
        },
      ];
    },

    //填充家长信息
    inputParentInfo(res) {
      return [
        {
          label: "id",
          value: res.id,
          show: true,
        },
        {
          label: "姓名",
          value: res.name,
        },
        {
          label: "联系电话",
          value: res.mobile,
        },
        {
          label: "性别",
          value: ["保密", "男", "女"][res.gender],
        },
        {
          label: "住址",
          value: res.address,
        },
        ...(res.student_relation_name
          ? [
              {
                label: "家长与学生的关系",
                value: res.student_relation_name,
              },
            ]
          : []),
        {
          label: "紧急联系人",
          value: res.contacts,
        },
        {
          label: "紧急联系电话",
          value: res.c_mobile,
        },
        {
          label: "身份证号码",
          value: res.card,
        },
        {
          label: "工作单位",
          value: res.company,
        },
        {
          label: "跟进客服（老师）",
          value: res.teacher_name,
        },
        {
          label: "原存费",
          value: res.ycf_balance,
        },
        {
          label: "备注",
          value: res.remark,
        },
      ];
    },

    // 编辑信息
    async editBaseInfoObj(index, id) {
      // console.log("index", index);
      // console.log("id", id);

      if (index === "student") {
        console.log("student", this.studentInfoRes);

        this.$nextTick(async () => {
          if (this.studentInfoRes.school_info) {
            const { name, xq_name } = this.studentInfoRes.school_info;
            this.studentInfoRes.school_name = name;
            this.studentInfoRes.xq_name = xq_name;
          }

          try {
            await this.$refs.addOrEditStudent.render({
              type: 2,
              inputedVal: this.studentInfoRes,
            });

            // 数据更新
            Object.assign(this.$data, this.$options.data.call(this));
            this.init();
            this.$bus_unique.emit("updateAuthenList");
            this.$bus_unique.emit("updateClassDetails");
          } catch (error) {
            this.$catchError(error);
          }
        });
      } else {
        console.log("parent", this.parentInfoRes);
        this.$nextTick(async () => {
          let item = this.parentInfoRes.find((item) => {
            return item.id === id;
          });
          // console.log("item", item);

          try {
            await this.$refs.addOrEditParent.render({
              type: 1,
              inputedVal: item,
            });

            // 数据更新
            Object.assign(this.$data, this.$options.data.call(this));
            this.init();
            this.$bus_unique.emit("updateAuthenList");
          } catch (error) {
            this.$catchError(error);
          }
        });
      }
    },

    // 已参加过项目登记提交
    async projectRegistrationFormSave() {
      console.log("projectRegistrationForm", this.projectRegistrationForm);

      try {
        await this.$ajax({
          apiKey: "saveArchives",
          data: {
            id: this.studentInfoRes.id,
            ...this.projectRegistrationForm,
          },
        });
        this.$message.success("保存成功");
        Object.assign(this.$data, this.$options.data.call(this));
        this.init();
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 拓展信息提交
    async expandInfoFormSave() {
      console.log("expandInfoFormSave", this.expandInfoForm);

      try {
        this.expandInfoForm.grxgtd = JSON.stringify(this.expandInfoForm.grxgtd);
        await this.$ajax({
          apiKey: "saveArchives",
          data: {
            id: this.studentInfoRes.id,
            ...this.expandInfoForm,
          },
        });
        this.$message.success("保存成功");
        Object.assign(this.$data, this.$options.data.call(this));
        this.init();
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 星级学员提交
    async internationalStudentsFormSave() {
      console.log("internationalStudentsForm", this.internationalStudentsForm);

      try {
        await this.$ajax({
          apiKey: "saveArchives",
          data: {
            id: this.studentInfoRes.id,
            ...this.internationalStudentsForm,
          },
        });
        this.$message.success("保存成功");
        Object.assign(this.$data, this.$options.data.call(this));
        this.init();
      } catch (error) {
        this.$catchError(error);
      }
    },
    // 班级信息提交
    async classInfoFormSave() {
      console.log("classInfoForm", this.classInfoForm);

      try {
        if (this.classInfoForm.course_id.length) {
          this.classInfoForm.course_id = this.classInfoForm.course_id.join(",");
        } else {
          this.classInfoForm.course_id = "";
        }

        await this.$ajax({
          apiKey: "saveArchives",
          data: {
            id: this.studentInfoRes.id,
            ...this.classInfoForm,
          },
        });
        this.$message.success("保存成功");
        Object.assign(this.$data, this.$options.data.call(this));
        this.init();
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 获取老师列表
    async getteacherList() {
      try {
        let { res } = await this.$ajax({
          apiKey: "teacherServiceList",
          data: {
            paginate: 100,
            status: 1,
          },
        });
        console.log("获取老师列表", res);
        this.teacherList = res.data;
      } catch (error) {
        this.$catchError(error);
      }
    },

    // 权限按钮判断
    ifOperationButton(authenKey) {
      const getters = this.$store.getters;
      const getAuthenIsPermitted = getters.getAuthenIsPermitted;
      const isGetAuthen = getAuthenIsPermitted(authenKey);
      // console.log('isGetAuthen', isGetAuthen)
      return isGetAuthen;
    },
  },
  computed: {
    //页面底部的操作按钮
    curentPageFooterBtnList() {
      let btnList = [
        {
          title: "导出",
          authenKey: "archives_export",
          type: "warning",
          onTap: async () => {
            this.$confirm("是否导出？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(async () => {
                try {
                  let { res } = await this.$ajax({
                    apiKey: "archives_export",
                    data: {
                      id: this.studentInfoRes.id,
                    },
                  });
                  console.log("res", res);
                  let name = `${this.studentInfoRes.name}-${["保密", "男", "女"][this.studentInfoRes.gender]}-${this.studentInfoRes.school_info.xq_name}`
                  saveAs(appConfig.httpDomainName + res.path, name + "-学生档案.pdf");
                  this.$message.success("导出成功");
                } catch (error) {
                  this.$catchError(error);
                }
              })
              .catch(() => {});
          },
        },
        {
          title: "历史跟进客服",
          authenKey: "parent_historyTeacherList",
          onTap: () => {
            this.initOptions_servicer = {
              parentID: this.currentParentID,
            };

            this.$refs.centerDialog_historyService.render({
              title: "历史跟进客服",
              width: "800px",
            });
          },
        },
        {
          title: "原存费记录列表",
          authenKey: "ycf_log",
          onTap: () => {
            this.initOptions_historyYCf = {
              parentID: this.currentParentID,
            };

            this.$refs.centerDialog_historyYCf.render({
              title: "原存费记录列表",
              width: "1000px",
            });
          },
        },
      ];
      return btnList;
    },
  },
};
</script>

<style lang="scss" scoped>
// 模块：导航
.category-list-modules {
  .categroy-item {
    line-height: 2.8;
    font-size: 0.9rem;
    color: #333333;

    &.active {
      position: relative;
      color: #1891ff;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        display: block;
        height: 2px;
        background-color: #1891ff;
      }
    }
  }
}

// 模块：普通信息
.normal-info-modules {
  .title {
    padding: 0.85rem 1.25rem;
    font-size: 0.8rem;
    color: #606060;
    border-bottom: 1px solid #eaeaea;
  }

  .body-table {
    margin-left: -1px;

    .td {
      padding-left: 1.15rem;
      border-left: 1px solid #efefef;

      .label {
        font-size: 0.7rem;
        color: #475774;
      }
      .value {
        font-size: 0.7rem;
        color: #606060;
      }
    }
  }
}

// 模块：购买的课程等信息

.nav-list {
  border-bottom: 1px solid #eaeaea;

  .nav-item {
    font-size: 0.8rem;
    color: #333;

    &.active {
      position: relative;
      color: #1891ff;

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        background-color: #1891ff;
      }
    }
  }
}
.nav-info-modules {
  .body {
    height: 40vh;
  }
}

::v-deep .projectRegistrationForm,
.expandInfoForm,
.internationalStudentsForm,
.classInfoForm {
  .el-form-item {
    width: 30%;

    .el-form-item__label {
      padding-bottom: 0px !important;
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>

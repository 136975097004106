/*
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-06 17:31:39
 * @FilePath: \src\assets\js\permitMap.js
 * 
 
 * @description: 权限id 映射表，配合 store/index.js 的权限方法。key值是后端权限id value是前端起的别名

 */

export default {
  //系统管理
  1: "systerm_manage",

  //角色
  15: "role_list",
  151: "role_add",
  152: "role_edit",
  153: "role_details",
  154: "role_del",

  //管理员
  16: "admin_list",
  161: "admin_add",
  162: "admin_edit",
  163: "admin_details",
  164: "admin_del",
  165: "admin_changePassword",
  166: "admin_changeStatus",

  // 操作日志
  17: "operateLog",

  //学校
  20: "school_list",
  201: "school_add",
  202: "school_edit",
  203: "school_details",
  204: "school_del",

  //校区管理
  21: "subSchool_list",
  211: "subSchool_add",
  212: "subSchool_edit",
  213: "subSchool_details",
  214: "subSchool_del",

  // //校区管理
  // 21: "category_list",
  // 211: "category_add",
  // 212: "category_edit",
  // 213: "category_details",
  // 214: "category_del",

  //年级
  22: "grade_list",
  221: "grade_add",
  222: "grade_edit",
  223: "grade_details",
  224: "grade_del",

  //区域
  23: "region_list",
  231: "region_add",
  232: "region_edit",
  233: "region_details",
  234: "region_del",

  //产品: 类型
  31: "goods_type_list",
  311: "goods_type_add",
  312: "goods_type_edit",
  313: "goods_type_details",
  314: "goods_type_del",

  //产品：班级分类
  32: "goods_classCategory_list",
  321: "goods_classCategory_add",
  322: "goods_classCategory_edit",
  323: "goods_classCategory_details",
  324: "goods_classCategory_del",

  //产品
  33: "goods_list",
  331: "goods_add",
  332: "goods_edit",
  333: "goods_details",
  334: "goods_del",
  335: "goods_isOnSale",
  336: "goods_isForbidden",
  337: "goods_sort",

  //产品：年级管理
  34: "goods_grade_list",
  341: "goods_grade_add",
  342: "goods_grade_edit",
  343: "goods_grade_details",
  344: "goods_grade_del",

  //产品：规格
  35: "goods_attr_list",
  351: "goods_attr_add",
  352: "goods_attr_edit",
  353: "goods_attr_details",
  354: "goods_attr_del",

  //产品：属性
  36: "goods_belong_list",
  361: "goods_belong_add",
  362: "goods_belong_edit",
  363: "goods_belong_details",
  364: "goods_belong_del",

  //家长管理
  42: "parent_list",
  421: "parent_add",
  422: "parent_edit",
  423: "parent_details",
  424: "parent_del",
  426: "parent_isForbidden", //启用\/禁用家长
  427: "parent_changeTeacher", //修改跟进客服
  428: "parent_historyTeacherList", //历史跟进客服列表
  53252: "ycf_log", //原存费日志
  429: "parent_delHistoryTeacher", //历史跟进客服列表
  4201: "parent_export", //家长：导出
  4202: "parent_importTemplate", //家长:导入模板
  4203: "parent_import", //家长： 导入
  53254: "parent_account_merge", // 账户合并

  //学生管理
  43: "student_list",
  431: "student_add",
  432: "student_edit",
  433: "student_details",
  434: "student_del",
  4301: "student_export", //学生:导出
  4302: "student_importTemplate", //学生:导入模板
  4304: "student_import", //学生： 导入
  435: "archives_export", //导出学生档案
  436: "save_archives", //编辑学生档案

  //学生和家长的关系
  44: "studentAndParentRelation_list",
  441: "studentAndParentRelation_add",
  442: "studentAndParentRelation_edit",
  443: "studentAndParentRelation_details",
  444: "studentAndParentRelation_del",

  //订单管理
  53160: "order_list",
  53161: "order_add",
  53162: "order_edit",
  53163: "order_details",
  53165: "order_check", //审核
  53164: "order_del",
  53166: "order_refund",
  53167: "order_importTemplate", //订单：订单导入模板
  53169: "order_import", //订单：订单导入
  53168: "order_export", //订单：导出

  53170: "order_refundRecordList", //退款记录列表
  53172: "order_refund_export", //退款订单：导出
  53171: "order_refund_check", //退款订单：审核

  //退款理由
  53154: "refundReason_list", //列表
  53155: "refundReason_add", //新增
  53156: "refundReason_edit", //编辑
  53157: "refundReason_details", //详情
  53158: "refundReason_del", //删除

  //班级管理
  61: "class_list",
  610: "class_add",
  625: "class_details",
  611: "class_edit",
  612: "class_del",
  613: "class_isForbidden", //启用\/禁用班级
  614: "class_addStudent",
  615: "class_moveStudentToOtherClass", //学生换班
  616: "class_outOfClass", //学生退班
  617: "class_unbindMainTeacher", //班主任解绑
  618: "class_studentList", //班级学生
  619: "class_clockCountList", //核销课时列表
  620: "class_delHistoryStudent", //删除历史学生
  622: "class_canJoinClass", //待进班级学生
  53129: "class_onDutyStudentList", //出勤学生列表
  53130: "class_absenceStudentList", //缺勤学生列表
  623: "class_export", //班级：导出
  624: "class_exportStudent", //班级学生：导出
  626: "makeMissedLessons", //班级补课
  

  //财务
  70: "finance_list", //统计报表
  700: "finance_details", //详情
  704: "finance_expendList", //支出列表
  701: "finance_incomeAndOutgoingAmount", //收支金额显示
  702: "finance_orderPrice", //订单价格显示
  703: "finance_refundPrice", //订单退款价格显示

  //会员等级
  53132: "memberLevel_list", //列表
  53133: "memberLevel_add", //新增
  53134: "memberLevel_edit", //编辑
  53135: "memberLevel_info", //详情
  53136: "memberLevel_del", //删除

  //会员充值规则
  53137: "memberRechargeRules_list", //列表
  53138: "memberRechargeRules_add", //新增
  53139: "memberRechargeRules_edit", //编辑
  53140: "memberRechargeRules_info", //详情
  53141: "memberRechargeRules_del", //删除
  53142: "memberRechargeRules_changeAbleUseStatus", //启用\/禁用

  //会员充值
  53143: "memberRecharge_list", //充值单列表
  53144: "memberRecharge_add", //充值
  53145: "memberRecharge_examine", //审核
  53146: "memberRecharge_del", //删除

  //会员资料
  53147: "member_list", //列表
  53148: "member_info", //详情
  53149: "member_del", //删除
  53150: "member_changeLevel", //修改等级
  53151: "member_rechargeRecordList", //充值记录列表
  53152: "member_consumptList", //消费记录列表
  53173: "member_recordConsumption", //消费记录列表
  53153: "member_export", //导出会员资料

  // 教师管理
  53176: "teacherServiceList", //列表
  53177: "teacherServiceAdd", //新增
  53178: "teacherServiceEdit", //编辑
  53179: "teacherServiceDetails", //详情
  53180: "teacherServiceDel", //删除
  53181: "teacherServiceChangeAbleUseStatus", //启用/禁用

  // 部门管理
  53183: "departmentList", //列表
  53184: "departmentAdd", //新增
  53185: "departmentEdit", //编辑
  53186: "departmentDetails", //详情
  53187: "departmentDel", //删除

  // 教师管理-操作日志
  53188: "teacherServiceLog",

  // 教研组列表
  53195: "investigatingList", //列表
  53196: "investigatingAdd", //新增
  53197: "investigatingEdit", //编辑
  53198: "investigatingDetails", //详情
  53199: "investigatingDel", //删除
  53200: "investigatingChangeAbleUseStatus", //启用/禁用

  // 课程体系列表
  53190: "curriculumSystemList", //列表
  53191: "curriculumSystemAdd", //新增
  53192: "curriculumSystemEdit", //编辑
  53193: "curriculumSystemDetails", //详情
  53194: "curriculumSystemDel", //删除

  // 教学大纲列表
  53201: "syllabusList", //列表
  53202: "syllabusAdd", //新增
  53203: "syllabusEdit", //编辑
  53207: "syllabusDetails", //详情
  53208: "syllabusDel", //删除
  53209: "syllabusStart", //启用
  53210: "syllabusEnd", //结束
  53211: "syllabusStatistics", //统计数据
  53243: "syllabusOperationLog", //操作日志列表

  // 考核表模板
  53225: "inspectionTemplateDetails", //详情
  53226: "inspectionTemplateEdit", //编辑
  53227: "inspectionTemplateExport", //导出

  // 排课表列表
  53230: "arrangingTableList", //列表
  53231: "arrangingTableAdd", //新增
  53232: "arrangingTableEdit", //编辑
  53233: "arrangingTableDetails", //详情
  53234: "arrangingTableDel", //删除
  53235: "arrangingTableShow", // 查看
  53236: "arrangingTableExport", //导出
  53237: "arrangingTableItemAdd", // 课程新增
  53238: "arrangingTableItemEdit", // 课程编辑
  53239: "arrangingTableItemDetails", // 课程详情
  53240: "arrangingTableItemDel", // 课程删除
  53241: "arrangingTablePushDetails", // 推送设置详情
  53242: "arrangingTablePushEdit", // 推送设置编辑

  // 课时统计
  53258: "classStatisticsList", //列表
  53256: "classStatisticsDetails", //详情

  // 项目模板
  53260: "projectTemplateList", //列表
  53262: "projectTemplateAdd", //新增
  53263: "projectTemplateEdit", //编辑
  53264: "projectTemplateDel", //删除
  53261: "projectTemplateGetInfo", //详情
  53266: "projectTemplateChangeStatus", //启用/禁用
};

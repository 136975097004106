<template>
  <div class="d-flex flex-column h-100" v-if="classDetails">
    <!-- 模块：班级信息 -->
    <div class="d-flex align-items-center mb-10 class-info-modules">
      <div
        class="info-item"
        v-for="(item, index) in classInfoLsit"
        :key="index"
      >
        <span class="label">{{ item.label }}：</span>
        <span class="mr-40 value" :class="item.cssClass_value"
          >{{ classDetails[item.props] || '--' }}
        </span>
      </div>
    </div>
    <mainContentDiv class="col pa-20">
      <tableList
        ref="tableList"
        v-if="tableOptions"
        :initOptions="tableOptions"
      ></tableList>

      <!-- 弹窗：缺勤学生 -->
      <centerDialog ref="centerDialog_absenceStudent">
        <div style="height: 60vh" v-if="initOptions_absenceStudent">
          <absenceStudentList
            :initOptions="initOptions_absenceStudent"
          ></absenceStudentList>
        </div>
      </centerDialog>
    </mainContentDiv>
  </div>
</template>

<script>
import absenceStudentList from '@/views/class/absenceStudentList.vue'

export default {
  components: {
    absenceStudentList,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_absenceStudent: null,
      classDetails: null,
    }
  },
  beforeMount() {
    this.tableOptions = this.getTableOptions()

    this.getClassDetails()
  },

  methods: {
    async getClassDetails() {
      try {
        const { classID } = this.$route.query

        const { res } = await this.$ajax({
          apiKey: 'getClassInfo',
          data: {
            cid: classID,
          },
        })

        this.classDetails = res
      } catch (error) {
        this.$catchError(error)
      }
    },
    getTableOptions() {
      return {
        authenKey: 'class_onDutyStudentList',
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
        ],
        search: {
          inputList: [
            {
              type: 'select',
              label: '是否全勤',
              value: 0,
              props: 'status',
              rules: ['!null'],
              options: [
                {
                  label: '全部',
                  value: 0,
                },
                {
                  label: '全勤',
                  value: 1,
                },
                {
                  label: '缺勤',
                  value: 2,
                },
              ],
            },
          ],
        },
        headers: [
          {
            label: '上课时间',
            props: 'class_time',
          },
          {
            label: '出勤人数',
            props: 'attend_count',
          },
          {
            label: '缺勤人数',
            props: 'absence_count',
          },
          {
            label: '操作人',
            props: 'admin_name',
          },
          {
            label: '是否全勤',
            props: '__isAllOnDuty',
          },
        ],
        actionBtns: [
          {
            title: '缺勤名单',
            color: 'red',
            authenKey: 'class_absenceStudentList',
            onTap: (options) => {
              this.initOptions_absenceStudent = false

              setTimeout(() => {
                try {
                  console.log('options', options)
                  if (options.status === 1) {
                    throw '当前班级全勤'
                  }

                  this.initOptions_absenceStudent = options

                  this.$refs.centerDialog_absenceStudent.render({
                    title: '缺勤名单',
                    width: '800px',
                  })
                } catch (error) {
                  this.$catchError(error)
                }
              }, 200)
            },
          },
        ],

        getData: async (options) => {
          const { classID } = this.$route.query

          let requestRet = await this.$ajax({
            apiKey: 'getOndutyStudentList',
            data: {
              ...options,
              class_id: classID,
            },
          })

          requestRet.res.data.forEach((item) => {
            const { status } = item

            item.__isAllOnDuty = status === 1 ? '是' : '否'
          })

          return requestRet
        },
      }
    },
  },
  computed: {
    classInfoLsit() {
      return [
        {
          label: '开设班级名称',
          props: 'name',
          cssClass_value: 'blue-text',
        },
        {
          label: '班主任',
          props: 'admin_name',
        },
        {
          label: '开课时间',
          props: 'created_at',
        },
        {
          label: '总课时',
          props: 'count',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
// 模块：班级信息
.class-info-modules {
  .label {
    font-size: 0.89rem;
    color: #333333;
  }

  .value {
    font-size: 0.89rem;
    color: #333333;

    &.blue-text {
      color: #1891ff;
    }
  }
}
</style>

<!--
 * @Date         : 2020-11-26 13:54:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-04 17:15:18
 * @FilePath     : \leXue_manage_pc\src\views\class\addOrEditClass.vue
-->
<template>
  <div>
    <!-- 弹窗：新增/编辑 -->
    <centerDialog ref="centerDialog" class="center-dialog">
      <div style="margin: -30px -20px">
        <div class="pl-20 dialog-title">班级基本信息</div>
        <div class="overflow-hidden">
          <div class="pa-20" v-if="inputCenter_classInfo">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputCenter_classInfo"
            ></inputCenter>
          </div>

          <div class="pl-20 dialog-title">产品信息</div>
          <div class="pa-20" v-if="inputCenter_productInfo">
            <inputCenter
              ref="inputCenter"
              :initOptions="inputCenter_productInfo"
            ></inputCenter>
          </div>
        </div>
      </div>
    </centerDialog>
  </div>
</template>

<script>
import goodsList from '@/views/goods/goodsList.vue'
import selectSkuByGoodsID from '@/views/goods/selectSkuByGoodsID.vue'

export default {
  components: {
    goodsList,
    selectSkuByGoodsID,
  },
  data() {
    return {
      inputCenter_classInfo: null,
      inputCenter_productInfo: null,
      labelValKeyList: [
        {
          label: 'classify_name',
          value: 'classify_id',
        },
        {
          label: 'grade_name',
          value: 'grade_id',
        },
        {
          label: 'spec_name',
          value: 'spec_id',
        },
        {
          label: 'attr_name',
          value: 'attr_id',
        },
      ],
    }
  },
  methods: {
    // 弹出新增/编辑
    render({ type, inputedVal }) {
      try {
        let inputCenter_classInfo = (this.inputCenter_classInfo = {
          inputList: this.getInputStudent(inputedVal),
        })

        let inputCenter_productInfo = (this.inputCenter_productInfo = {
          inputList: this.getInputProduct(inputedVal),
        })
        console.log('inputCenter_productInfo', inputCenter_productInfo)

        return new Promise((resovle, reject) => {
          this.$refs.centerDialog.render({
            title: ['新增班级', '编辑班级'][type],
            width: '800px',
            //关闭前的数据校验，如果数据发生了改变，则提醒关闭不保存数据
            ...(type === 0
              ? {
                  verifyInputListIsChange: [
                    ...inputCenter_classInfo.inputList,
                    ...inputCenter_productInfo.inputList,
                  ],
                }
              : {}),
            ok: async () => {
              try {
                let requestOpt = {
                  //获取数据: 班级信息
                  ...this.$com.getValueByRules(inputCenter_classInfo.inputList),
                  //获取数据: 产品信息
                  ...this.$com.getValueByRules(
                    inputCenter_productInfo.inputList
                  ),
                }

                //0：新增 1：编辑
                if (type === 1) {
                  requestOpt.id = inputedVal.id //cid	否	int	班级id，编辑时该参数必须
                  // 助教转换
                  if (
                    requestOpt.ass_ids &&
                    requestOpt.ass_ids.constructor === Array
                  ) {
                    requestOpt.ass_ids = JSON.stringify(requestOpt.ass_ids)
                  }
                }

                // let proSkuList = []
                // for (let i of requestOpt.pro_sku) {
                //   proSkuList.push({
                //     pro_id: requestOpt.pro_id,
                //     sku_id: i,
                //   })
                // }
                // requestOpt.pro_sku = JSON.stringify(proSkuList)

                requestOpt.pro_id = requestOpt.pro_id.join(',')
                console.log('requestOpt', requestOpt)

                const { msg } = await this.$ajax({
                  apiKey: 'saveClass',
                  data: requestOpt,
                })

                this.$toast({
                  msg,
                })

                resovle()
              } catch (error) {
                reject(error)
                throw error
              }
            },
            cancel: () => {
              reject(null)
              //销毁已选择的产品和规格
              this.tableDataList_productSku = []
            },
          })
        })
      } catch (error) {
        this.$catchError(error)
      }
    },

    //获取输入配置表
    getInputStudent(inputedVal) {
      let _tempValObj = (this._tempValObj = {}) //临时变量

      let getInputStudent = [
        {
          type: 'input',
          label: '班级名称',
          placeholder: '请输入班级名称',
          maxLength: 40,
          rules: ['required', '!null'],
          value: null,
          props: 'name', //name	是	string	班级名称
          col: 'col-3',
        },
        //选择班主任
        (_tempValObj.belongTeacher = {
          type: 'select',
          label: '班主任',
          placeholder: '请搜索/选择班主任',
          rules: ['required', '!null'],
          value: '',
          id: '',
          props: 'teacher_name',
          props_id: 'teacher_id', //teacher_id	是	int	班主任id
          col: 'col-3',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              console.info('keyword', keyword)

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'teacherServiceList',
                data: {
                  name: keyword || '',
                  status: 1, //status	否	int	管理员状态 1-正常 2-禁用
                },
              })

              _tempValObj.belongTeacher.options = data.map((item) => {
                const { id, name } = item

                return {
                  label: name,
                  value: id,
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
          onChange: (val, thisObj) => {
            try {
              //限制：班主任和助教不能是同一个人
              const thisTeacherInAssListIndex = _tempValObj.assTeacher.value.findIndex(
                (item) => item === val
              )

              if (thisTeacherInAssListIndex !== -1) {
                thisObj.value = '' //清空输入
                throw '该选择已存在助教中, 请重新选择'
              }

              //赋值
              thisObj.id = val
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
        //选择助教
        (_tempValObj.assTeacher = {
          type: 'select',
          label: '助教',
          placeholder: '请搜索/选择助教',
          rules: ['!null'],
          value: [],
          id: '',
          multiple: true, //多选
          props: 'ass_names',
          props_id: 'ass_ids', //ass_ids	是	array	助教id集
          col: 'col-3',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'teacherServiceList',
                data: {
                  name: keyword || '', //name	否	string	管理员名称
                  status: 1, //status	否	int	管理员状态 1-正常 2-禁用
                },
              })

              const assTeacherList = (_tempValObj.assTeacher.options = data.map(
                (item) => {
                  const { id, name } = item

                  return {
                    label: name,
                    value: id,
                  }
                }
              ))

              return assTeacherList
            } catch (error) {
              this.$catchError(error)
            } finally {
            }
          },
          onChange: (valList, thisObj) => {
            try {
              //限制：班主任和助教不能是同一个人
              const currentMainTeacherID = _tempValObj.belongTeacher.id
              const thisTeacherMainTeacherIndex = valList.findIndex(
                (item) => Number(item) === Number(currentMainTeacherID)
              )

              console.info('options', thisObj.options)

              if (thisTeacherMainTeacherIndex !== -1) {
                thisObj.value.splice(thisTeacherMainTeacherIndex, 1) //移除该冲突值
                throw '该选择已存在班主任中, 请重新选择'
              }

              //赋值
              thisObj.id = JSON.stringify(valList)
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
        {
          type: 'datePicker',
          label: '开班时间',
          placeholder: '请选择开班时间',
          maxLength: 40,
          suffixIcon: 'el-icon-date',
          rules: ['required', '!null'],
          value: null,
          props: 'start_time', //start_time	是	string	开班时间
          col: 'col-3',
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd'
            )

            item.value = formatDate
          },
        },
        {
          type: 'datePicker',
          label: '结班时间',
          placeholder: '请选择结班时间',
          maxLength: 40,
          suffixIcon: 'el-icon-date',
          rules: ['required', '!null'],
          value: null,
          props: 'end_time', //end_time	是	string	结班时间
          col: 'col-3',
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd'
            )

            item.value = formatDate
          },
        },
        {
          type: 'input',
          label: '总课时',
          placeholder: '请输入总课时',
          maxLength: 40,
          rules: ['required', '!null', 'num'],
          value: null,
          props: 'count', //count	是	int	总课时
          col: 'col-3',
        },
        {
          type: 'input',
          label: '上课地点',
          placeholder: '请输入上课地点',
          maxLength: 80,
          rules: ['required', '!null'],
          value: null,
          props: 'address', //address		是	int	总课时
          col: 'col-3',
        },
        {
          type: 'input',
          label: '备注',
          placeholder: '请输入备注',
          maxLength: 40,
          value: null,
          rules: ['!null'],
          props: 'remark', //remark	否	string	备注
          col: 'col-3',
        },
      ]

      const getters = this.$store.getters
      const getAuthenIsPermitted = getters.getAuthenIsPermitted

      if (getAuthenIsPermitted('admin_list')) {
        _tempValObj.belongTeacher.remoteMethod() //获取所有老师
        //获取所有助教
        _tempValObj.assTeacher.remoteMethod().then((assTeacher) => {
          //回显：已选择的助教
          if (inputedVal) {
            const existedTeacherIDObj = {}

            assTeacher.forEach((item) => {
              existedTeacherIDObj[item.value] = true
            })

            inputedVal.ass_ids_names.forEach((item) => {
              const { name, id } = item

              console.info('item.id', item.id)
              console.info('item.name', item.name)

              const isExistedThisTeacher = existedTeacherIDObj[id]

              // 没有存在这个助教
              if (!isExistedThisTeacher) {
                assTeacher.push({
                  label: name,
                  value: id,
                })
              }
            })

            console.info('assTeacher', assTeacher)
          }
        })
      } else {
        if (inputedVal) {
          const assTeacherOptions = _tempValObj.assTeacher.options

          inputedVal.ass_ids_names.forEach((item) => {
            assTeacherOptions.push({
              label: item.name,
              value: item.id,
            })
          })
        }
      }

      //回显：编辑好的数据
      if (inputedVal) {
        this.$com.fillData({
          inputList: getInputStudent,
          valueListObj: inputedVal,
        })
      }

      return getInputStudent
    },

    getInputProduct(inputedVal) {
      const _tempValObj = {}

      let getInputProductList = [
        //选择产品
        (_tempValObj.product = {
          type: 'select',
          label: '产品名称',
          placeholder: '请搜索/选择产品',
          rules: ['required', '!null'],
          value: '',
          props: 'pro_id',
          col: 'col-12',
          options: [],
          multiple: true, //多选
          remoteMethod: async (keyword) => {
            try {
              console.info('keyword', keyword)

              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'getProdutList',
                data: {
                  name: keyword || '',
                  paginate: 10
                },
              })

              _tempValObj.product.options = data.map((item) => {
                const { id, name, type_name } = item

                return {
                  label: name,
                  value: id,
                  typeName: type_name,
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
          onChange: async (val, thisObj) => {
            try {
              const { options, value } = thisObj
              const { product, productDetails } = _tempValObj

              const currentOpt = options.find((item) => item.value === value)

              if (!currentOpt) {
                return false
              }

              //设置产品id
              product.id = currentOpt.value

              // //清空产品规格详情
              // productDetails.value = null
              // productDetails.key += 1 //特殊操作：使得级联选择器更换value时不会报错
              // productDetails.disabled = true

              // //重新获取并设置产品规格详情
              // let { res } = await this.$ajax({
              //   apiKey: 'getProductDetails',
              //   data: {
              //     id: value,
              //   },
              // })

              // const { sku } = res
              // const rootArr = []

              // this.getProductSku(rootArr, sku)

              // productDetails.options = rootArr
              // productDetails.disabled = false
              // productDetails.skuList = sku
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
        // //选择产品规格
        // (_tempValObj.productDetails = {
        //   type: 'cascader',
        //   expandTrigger: 'hover',
        //   label: '规格明细',
        //   placeholder: '请选择产品规格明细',
        //   rules: ['required', '!null'],
        //   value: [],
        //   id: '',
        //   props: 'pro_sku_name',
        //   props_id: 'pro_sku',
        //   col: 'col',
        //   disabled: true,
        //   options: [],
        //   lazyProps: {
        //     multiple: true,
        //   },
        //   key: 0,
        //   onChange: (valueList) => {
        //     const { productDetails } = _tempValObj
        //     const labelValKeyList = this.labelValKeyList

        //     console.log('valueList', valueList)
        //     let list = []
        //     for (let i of valueList) {
        //       //找出当前sku的id
        //       const currentSku = productDetails.skuList.find((itemSku) => {
        //         let isThisSku = true

        //         labelValKeyList.forEach((item, index) => {
        //           if (itemSku[item.value] !== i[index]) {
        //             isThisSku = false
        //           }
        //         })

        //         return isThisSku
        //       })
        //       console.info('currentSku', currentSku)
        //       currentSku && list.push(currentSku.id)
        //     }
        //     console.log('list', list)

        //     productDetails.id = list

        //     console.info('productDetails.value', productDetails.value)
        //   },
        // }),
      ]

      if (inputedVal) {
        console.log('inputedVal', inputedVal)

        if (!Array.isArray(inputedVal.pro_id)) {
          let nums = inputedVal.pro_id.split(',')
          nums.forEach((item, index) => {
            nums[index] = parseInt(nums[index])
          })
          // console.log('nums', nums)
          inputedVal.pro_id = nums
        }
        inputedVal.pro_name_list = inputedVal.pro_name.split(',')

        //回显: 产品名称/分类等
        this.$com.fillData({
          inputList: getInputProductList,
          valueListObj: inputedVal,
        })
      }

      _tempValObj.product.remoteMethod().then(() => {
        if (inputedVal) {
          const { product, productDetails } = _tempValObj
          const currentProduct = inputedVal
          console.log('currentProduct', currentProduct)

          //回显：把当前选择的产品输入options里面，便于回显
          currentProduct.pro_id.forEach((i, index) => {
            console.log('product.options', product.options)
            const isExistedThisProduct = product.options.find((item) => {
              return item.value === i
            })
            console.log('isExistedThisProduct', isExistedThisProduct)

            if (!isExistedThisProduct) {
              console.log('i', i)
              console.log('currentProduct.pro_name_list', currentProduct.pro_name_list)
              console.log('currentProduct.pro_id', currentProduct.pro_id)
              product.options.push({
                label: currentProduct.pro_name_list[index],
                value: i,
              })
            }
          })
          console.log('product', product.options)

          // product.onChange(product.value, product).then(() => {
          //   //回显：已选择的产品规格
          //   const valueList = []

          //   console.log('currentProduct', currentProduct)
          //   for (let i of currentProduct) {
          //     let list = []
          //     this.labelValKeyList.forEach((item) => {
          //       list.push(i[item.value])
          //     })
          //     valueList.push(list)
          //   }
          //   console.log('valueList', valueList)

          //   productDetails.value = valueList
          //   productDetails.disabled = false
          //   productDetails.onChange(valueList)
          // })
        }
      })

      return getInputProductList
    },

    //初始化方法，获取商品sku
    getProductSku(...options) {
      const getProductSku = this.getProductSku
      const { render } = getProductSku

      if (render) {
        return render(...options)
      } else {
        const labelValKeyList = this.labelValKeyList
        const labelValKeyListLen = labelValKeyList.length

        const render = (parentChild, rootData, currentLevel = 0) => {
          const {
            label: currentLabelKey,
            value: currentValKey,
          } = labelValKeyList[currentLevel]

          rootData.forEach((item) => {
            const label = item[currentLabelKey]
            const value = item[currentValKey]
            const hasThis = parentChild.find((item) => item.value === value)

            if (!hasThis) {
              const currentChild = {
                label,
                value,
              }

              parentChild.push(currentChild)

              if (currentLevel !== labelValKeyListLen - 1) {
                render((currentChild.children = []), rootData, currentLevel + 1)
              }
            }
          })
        }

        getProductSku.labelValKeyList = labelValKeyList
        getProductSku.render = render
        return render(...options)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.center-dialog {
  .dialog-title {
    line-height: 2.28rem;
    background-color: #f5f5f5;
    font-size: 0.89rem;
    color: #333333;
  }
}
</style>

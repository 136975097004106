<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：可新增到班级的学生列表 -->
    <centerDialog ref="centerDialog_addNewStudent">
      <div style="height: 60vh" v-if="initOptions_addStudent">
        <ableAddToClassStudentList
          ref="ableAddToClassStudentList"
          :initOptions="initOptions_addStudent"
        ></ableAddToClassStudentList>
      </div>
    </centerDialog>

    <!-- 弹窗：选择换班 -->
    <centerDialog ref="centerDialog_selectClass">
      <div style="height: 60vh" v-if="initOptions_chooseClass">
        <classList
          ref="classList"
          :initOptions="initOptions_chooseClass"
        ></classList>
      </div>
    </centerDialog>

    <!-- 弹窗：历史学生 -->
    <centerDialog ref="centerDialog_hisStudent">
      <div style="height: 60vh" v-if="initOptions_hisStudent">
        <historyStudentList
          ref="classList"
          :initOptions="initOptions_hisStudent"
        ></historyStudentList>
      </div>
    </centerDialog>

    <!-- 详情 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="FormDialogBox"
      width="700px"
      append-to-body
      title="查看详情"
      fullscreen
    >
      <studentDetails
        v-if="dialogVisible"
        :dialog="true"
        :pageType="1"
        :studentID="studentID"
        :parentID="parentID"
      />
    </el-dialog>

    <!-- 补课 -->
    <el-dialog
      title="补课"
      :visible.sync="makeMissedLessonsDialogShow"
      width="30%"
    >
      <div>
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="makeMissedLessonsDate"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="makeMissedLessonsDialogShow = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="makeMissedLessonsSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </mainContentDiv>
</template>

<script>
import ableAddToClassStudentList from "@/views/class/ableAddToClassStudentList.vue";
import classList from "@/views/class/classList.vue";
import historyStudentList from "@/views/class/historyStudentList.vue";
import studentDetails from "@/views/customer/studentDetails.vue";

export default {
  components: {
    ableAddToClassStudentList,
    classList,
    historyStudentList,
    studentDetails,
  },
  data() {
    return {
      tableOptions: null,
      initOptions_addStudent: null,
      initOptions_chooseClass: null,
      initOptions_hisStudent: null,
      dialogVisible: false,
      studentID: null,
      parentID: null,
      // 补课
      makeMissedLessonsDialogShow: false,
      makeMissedLessonsItem: null,
      makeMissedLessonsDate: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        },
      },
    };
  },
  destroyed() {
    this.$bus_unique.off("updateClassDetails");
  },
  mounted() {
    try {
      const { id, className } = this.$route.query;

      if (!id) {
        throw "班级id有误";
      }

      if (className) {
        this.$bus_unique.emit("updateContentNavTitle", {
          title: className,
        });
      }

      this.classID = id;
      this.tableOptions = this.getTableOptions();

      //监听：更新列表
      this.$bus_unique.on("updateClassDetails", "classDetails", () => {
        this.$refs.tableList.init();
      });
    } catch (error) {
      this.$catchError(error);
    }
  },
  methods: {
    getTableOptions() {
      return {
        authenKey: "class_studentList",
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "新增班级学生",
            authenKey: "class_canJoinClass",
            onTap: () => {
              try {
                const { id } = this.$route.query;

                if (!id) {
                  throw "缺少参数：id";
                }

                this.initOptions_addStudent = {
                  id,
                };

                this.$refs.centerDialog_addNewStudent.render({
                  title: "选择学生",
                  width: "1200px",
                  ok: async () => {
                    try {
                      const { id } = this.$route.query;
                      const {
                        selectedTrList,
                      } = this.$refs.ableAddToClassStudentList;

                      console.log("selectedTrList", selectedTrList);

                      if (!selectedTrList) {
                        throw "请至少选择一个学生";
                      }

                      const studentInfoList = selectedTrList.map((item) => {
                        const { id, sid } = item;

                        return {
                          op_id: id, //op_id	是	int	订单关联产品id
                          sid, //sid	是	int	学生id
                        };
                      });

                      if (studentInfoList.length <= 0) {
                        throw "请至少选择一个学生";
                      }

                      const { msg } = await this.$ajax({
                        apiKey: "addClassStudent",
                        data: {
                          cid: id, //cid	是	int	班级id
                          ops_sids: JSON.stringify(studentInfoList), //ops_sids	是	array	ops_sids集 [ {op_id , sid } , {.. } ..]
                        },
                      });

                      this.$refs.tableList.init(); //刷新table

                      this.$toast({
                        color: "success",
                        msg,
                      });
                    } catch (error) {
                      throw error;
                    }
                  },
                  cancel: () => {},
                  complete: () => {
                    this.initOptions_addStudent = null;
                  },
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
          {
            title: "历史学生记录",
            authenKey: "student_list",
            onTap: () => {
              this.initOptions_hisStudent = {
                classID: this.classID,
                onChange: () => {
                  this.$refs.tableList.init();
                },
              };

              //弹出：班级历史学生
              this.$refs.centerDialog_hisStudent.render({
                title: "班级历史学生",
                width: "800px",
                cancel: () => {
                  this.initOptions_hisStudent = null; //销毁组件
                },
              });
            },
          },
          {
            title: "导出",
            color: "normal",
            authenKey: "class_exportStudent",
            onTap: async (thisBtn) => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportClassStudent",
                  fileName: "班级学生列表.xls",
                  data: {
                    ...thisBtn.keyword,
                    cid: this.classID,
                  },
                  submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        search: {
          inputList: [
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "手机号",
              placeholder: "",
              value: "",
              props: "mobile",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          {
            label: "学生名称",
            props: "student_name",
          },
          {
            label: "家长名称",
            props: "parent_name",
          },
          {
            label: "家长手机号",
            props: "parent_mobile",
          },
          {
            label: "学生状态",
            props: "_statusText",
          },
          { label: "所在学校", props: "school_name" },
          { label: "所在年级", props: "grade_name" },
          { label: "产品名称", props: "_buyyedProduct" },
          { label: "产品规格", props: "_specificationDetails" },
          { label: "订单编号", props: "order_id" },
          { label: "订单类型", props: "order_type_str" },
          { label: "剩余课时", props: "surplus_count" },
          { label: "剩余余额（元）", props: "surplus_price" },
        ],
        actionBtns: [
          {
            title: "学生详情",
            onTap: async (optionsStudent) => {
              console.log("optionsStudent", optionsStudent);
              // this.$router.push({
              //   name: 'studentDetails',
              //   query: {
              //     pageType: 1,
              //     studentID: optionsStudent.sid, //学生id
              //   },
              // })

              this.studentID = optionsStudent.sid;
              this.dialogVisible = true;
            },
          },
          {
            title: "转班",
            color: "red",
            authenKey: "class_moveStudentToOtherClass",
            onTap: async (optionsStudent) => {
              this.initOptions_chooseClass = {
                onSelect: async (optionsClass) => {
                  try {
                    const timeStamp = new Date().getTime();
                    const formatDate = this.$com.formatDate(
                      new Date(timeStamp),
                      "yyyy-MM-dd"
                    );

                    const { msg } = await this.$ajax({
                      apiKey: "moveStudentToOtherClass",
                      data: {
                        old_cid: this.classID, //old_cid: this.classID,//old_cid	是	int	旧班级id
                        new_cid: optionsClass.id, //new_cid	是	int	新班级id
                        sid: optionsStudent.sid, //sid	是	int	学生id
                        add_time: formatDate, //add_time	是	string	转班时间
                      },
                    });

                    this.$refs.centerDialog_selectClass.hide(); //弹窗：关闭
                    this.$refs.tableList.init(); //刷新学生列表

                    this.$toast({
                      msg,
                    });
                  } catch (error) {
                    this.$catchError(error);
                  }
                },
              };

              //弹出：选择班级
              this.$refs.centerDialog_selectClass.render({
                width: "800px",
                title: "选择目标班级",

                cancel: () => {
                  this.initOptions_chooseClass = null;
                },
              });
            },
          },
          {
            title: "退班",
            color: "red",
            authenKey: "class_outOfClass",
            onDelete: async (options) => {
              return await this.$ajax({
                responseAll: true,
                apiKey: "removeStudentOutOfCurrentClass",
                data: {
                  cid: this.classID, //cid	是	int	班级id
                  sid: options.sid, //sid	是	int	学生id
                },
              });
            },
          },
          {
            title: "补课",
            color: "blue",
            authenKey: "makeMissedLessons",
            onTap: async (options) => {
              console.log("补课", options);
              this.makeMissedLessonsItem = options;
              this.makeMissedLessonsDialogShow = true;
            },
          },
        ],
        getData: async (options) => {
          let requestRet = await this.$ajax({
            apiKey: "getClassStudent",
            data: {
              ...options,
              cid: this.classID,
              type: 1, //type	否	int b状态类型 1-在读（正常）2-历史(转班，退班，结班) ——默认全部
            },
          });

          for (let i of requestRet.res.data) {
            i._specificationDetails = `${i.classify_name} / ${i.grade_name} / ${i.spec_name} / ${i.attr_name}`;
            i._buyyedProduct = `${i.pro_name}`;
          }

          return requestRet;
        },
      };
    },

    // 补课提交
    async makeMissedLessonsSubmit() {
      // console.log("makeMissedLessonsItem", this.makeMissedLessonsItem);
      // console.log("makeMissedLessonsDate", this.makeMissedLessonsDate);

      if (!this.makeMissedLessonsDate) {
        return this.$message("请选择日期");
      }

      let form = {
        cid: this.makeMissedLessonsItem.cid,
        sid: this.makeMissedLessonsItem.sid,
        check_date: this.makeMissedLessonsDate,
      };
      console.log("form", form);
      await this.$ajax({
        apiKey: "teachBuke",
        data: form,
      });
      this.$message({
        message: "已补课",
        type: "success",
      });
      this.$refs.tableList.init(); //刷新table
      this.makeMissedLessonsDialogShow = false;
    },
  },
};
</script>

<style lang="scss">
.FormDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>

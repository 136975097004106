<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-04-27 09:56:23
 * @FilePath     : \leXue_manage_pc\src\views\member\memberRechargeRecordList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "member_rechargeRecordList",

        headers: [
          { label: "充值金额", props: "recharge_money" },
          { label: "赠送金额", props: "give_money" },
          { label: "创建时间", props: "created_at" },
        ],
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          const { parentID } = this.initOptions;

          let ret = await this.$ajax({
            apiKey: "getMemberRechargeRecordList",
            data: {
              ...options,
              pid: parentID || "", //pid	否	int	家长id
            },
          });

          return ret;
        },
      };

      return getTableOptions;
    },
  },
};
</script>

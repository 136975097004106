<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-09 09:24:45
 * @FilePath     : \leXue_manage_pc\src\views\member\recharge.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 弹窗：新增/编辑充值 -->
    <centerDialog ref="centerDialog_recharge">
      <inputCenter :initOptions="initOptions_recharge"></inputCenter>
    </centerDialog>
  </mainContentDiv>
</template>

<script>
import debounce from '@/assets/js/debounce.js'

export default {
  data() {
    return {
      tableOptions: null,
      initOptions_recharge: null,
    }
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions()
  },
  methods: {
    //弹出新增/编辑学校对话框
    renderRechargeDialog(type, tableItem) {
      let inputOptions = {
        inputList: this.getInputList_recharge(),
      }

      this.initOptions_recharge = inputOptions

      // 已编辑的值回填
      if (tableItem) {
        this.$com.fillData({
          inputList: inputOptions.inputList,
          valueListObj: tableItem,
        })
      }

      this.$refs.centerDialog_recharge.render({
        title: '充值',
        width: '500px',
        verifyInputListIsChange: inputOptions.inputList,
        closeOnClickModal: false,
        ok: async () => {
          try {
            const { inputList } = inputOptions

            let requestOpt = this.$com.getValueByRules(inputList)
            console.log('requestOpt', requestOpt)

            const { msg } = await this.$ajax({
              apiKey: 'rechargeMember',
              data: requestOpt,
            })

            this.$refs.tableList.init()
            this.$toast({
              msg,
            })
          } catch (error) {
            throw error
          }
        },
        cancel: () => {},
      })
    },

    //批量审核
    async examineRechargeByBatch(idList) {
      try {
        if (this.isSubmitted_examine === true) {
          return false
        }

        this.isSubmitted_examine = true

        const { msg } = await this.$ajax({
          apiKey: 'checkMemberRecharge',
          data: {
            ids: JSON.stringify(idList),
          },
        })

        this.$refs.tableList.init()
        this.$toast({
          msg,
        })

        setTimeout(() => {
          this.isSubmitted_examine = false
        }, 300)
      } catch (error) {
        this.isSubmitted_examine = false
        this.$catchError(error)
      }
    },

    getInputList_recharge() {
      const _tempValObj = {}

      const inputList_recharget = [
        //选择学生
        (_tempValObj.student = {
          type: 'select',
          label: '学生姓名',
          placeholder: '请搜索/选择学生姓名',
          rules: ['required', '!null'],
          value: '',
          props: 'sid',
          col: 'col-6',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'getStudentList',
                data: {
                  student_name: keyword || '',
                },
              })

              _tempValObj.student.options = data.map((item) => {
                const { id, name, parent_info } = item

                return {
                  label: `${name}`,
                  value: id,
                  parentList: parent_info.map((item) => ({
                    label: `${item.name}(${item.mobile})`,
                    value: item.id,
                  })),
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
          onChange: (studenID, thisObj) => {
            const { parent } = _tempValObj

            //清空
            parent.value = null
            parent.options.length = 0
            parent.disabled = true

            if (!studenID) return false

            //筛选出当前的家长列表
            const { parentList } = thisObj.options.find(
              (item) => item.value === studenID
            )

            parent.options = parentList

            if (parentList.length === 1) {
              parent.value = parentList[0].value
            } else {
              parent.disabled = false
            }
          },
        }),
        // 选择家长
        (_tempValObj.parent = {
          type: 'select',
          label: '家长信息',
          placeholder: '请选择家长信息',
          rules: ['required', '!null'],
          value: '',
          props: 'pid',
          col: 'col-6',
          options: [],
          disabled: true,
        }),
        // //选择学生的家长
        // (_tempValObj.parent = {
        //   type: "cascader",
        //   label: "选择学生",
        //   placeholder: "请选择学生",
        //   rules: ["required", "!null"],
        //   value: null,
        //   props: "pName",
        //   props_id: "pid",
        //   col: "col-6",
        //   lazyProps: {
        //     lazy: true,
        //     multiple: false,
        //     lazyLoad: async (node, resolve) => {
        //       try {
        //         const { value, level } = node;

        //         if (level === 0) {
        //           //先用学会是那个
        //           const {
        //             res: { data },
        //           } = await this.$ajax({
        //             apiKey: "getStudentList",
        //             data: {
        //               paginate: 50,
        //             },
        //           });

        //           let nodes = data.map((item) => {
        //             return {
        //               value: item.id,
        //               label: item.name,
        //               leaf: false,
        //               parentList: item.parent_info.map((item) => ({
        //                 label: `${item.name}(${item.mobile})`,
        //                 value: item.id,
        //                 leaf: true,
        //               })),
        //             };
        //           });

        //           _tempValObj.studentNodes = nodes;

        //           resolve(nodes);
        //         } else if (level === 1) {
        //           const currentNode = _tempValObj.studentNodes.find(
        //             (item) => item.value === value
        //           );
        //           resolve(currentNode.parentList);
        //         }
        //       } catch (error) {
        //         this.$catchError(error);
        //       }
        //     },
        //   },
        //   onChange: (valueList, thisObj) => {
        //     if (!valueList || valueList.length <= 0) {
        //       return false;
        //     }

        //     thisObj.id = valueList[valueList.length - 1];
        //   },
        // }),
        {
          type: 'radio',
          label: '支付类型',
          placeholder: '请选择支付类型',
          rules: ['!null'],
          value: 3,
          props: 'pay_type',
          options: [
            {
              label: '现金',
              value: 1,
            },
            {
              label: '银行卡',
              value: 2,
            },
            {
              label: '微信',
              value: 3,
            },
            {
              label: '支付宝',
              value: 4,
            },
            {
              label: '校付通',
              value: 5,
            },
          ],
          col: 'col-6',
        },
        {
          type: 'input',
          label: '充值金额（单位：元）',
          placeholder: '请输入充值金额',
          maxLength: 50,
          rules: ['required', '!null'],
          value: null,
          props: 'recharge_money',
          col: 'col-6',
          onInput: debounce(
            async (thisObj) => {
              try {
                const { value } = thisObj
                const parentID = _tempValObj.parent.value

                if (!parentID) {
                  throw '请先选择家长'
                }

                const { res, msg } = await this.$ajax({
                  apiKey: 'computeRechargeGiveMoney',
                  data: {
                    pid: parentID,
                    recharge_money: value,
                  },
                })

                console.info('_tempValObj.giveMoney', _tempValObj.giveMoney)

                _tempValObj.giveMoney.value = res
                  ? res.give_money
                  : msg.give_money
              } catch (error) {
                this.$catchError(error)
              }
            },
            500,
            false
          ),
        },
        (_tempValObj.giveMoney = {
          type: 'input',
          label: '赠送金额（单位：元）',
          placeholder: '请输入赠送金额',
          maxLength: 50,
          rules: ['required', '!null'],
          value: null,
          props: 'give_money',
          col: 'col-6',
        }),
        //选择客服老师
        (_tempValObj.teacher = {
          type: 'select',
          label: '客服老师',
          placeholder: '请搜索/选择客服老师',
          rules: ['required', '!null'],
          value: '',
          props: 'teacher_id',
          col: 'col-6',
          options: [],
          remoteMethod: async (keyword) => {
            try {
              const {
                res: { data },
              } = await this.$ajax({
                apiKey: 'teacherServiceList',
                data: {
                  name: keyword || '',
                  status: 1,
                  type: 1,
                },
              })

              _tempValObj.teacher.options = data.map((item) => {
                const { id, name } = item

                return {
                  label: `${name}`,
                  value: id,
                }
              })
            } catch (error) {
              this.$catchError(error)
            }
          },
        }),
      ]

      _tempValObj.teacher.remoteMethod()

      return inputList_recharget
    },
    getTableOptions() {
      const { orderType } = this.$route.query

      return {
        authenKey: 'memberRecharge_list',
        search: {
          inputList: [
            {
              type: 'input',
              label: '家长名称',
              placeholder: '',
              value: '',
              props: 'parent_name',
              rules: ['!null'],
            },
            {
              type: 'input',
              label: '学生名称',
              placeholder: '',
              value: '',
              props: 'student_name',
              rules: ['!null'],
            },
            {
              type: 'input',
              label: '家长手机号码',
              maxLength: 11,
              value: '',
              props: 'mobile',
              rules: ['!null, mobile'],
            },
            {
              type: 'input',
              label: '客服老师',
              maxLength: 11,
              value: '',
              props: 'teacher_name',
              rules: ['!null'],
            },
            {
              type: 'select',
              label: '订单类型',
              placeholder: '',
              value: Number(orderType) || '',
              props: 'type',
              rules: ['!null'],
              options: [
                {
                  label: '乐学教务',
                  value: 1,
                },
                {
                  label: '七彩童年',
                  value: 2,
                },
              ],
            },
            {
              type: 'select',
              label: '审核状态',
              placeholder: '',
              value: '',
              props: 'status',
              rules: ['!null'],
              options: [
                {
                  label: '已审核',
                  value: 1,
                },
                {
                  label: '未审核',
                  value: 2,
                },
              ],
            },
            {
              type: 'radioBtnList',
              label: '时间筛选',
              value: 0,
              props: 'time_type', //time_type	否	int	时间类型 1-本月 2-上月 3-本年 4-去年 5-自定义
              rules: ['!null'],
              options: [
                {
                  label: '全部',
                  value: 0,
                },
                {
                  label: '本月',
                  value: 1,
                },
                {
                  label: '上月',
                  value: 2,
                },
                {
                  label: '本年',
                  value: 3,
                },
                {
                  label: '去年',
                  value: 4,
                },
                {
                  label: '自定义',
                  value: 5,
                },
              ],
              onChange: (val, thisObj, inputList) => {
                //增加：自定义时间选择控件
                if (val === 5) {
                  const startAndEndTimeRange = this.getStartAndEndTimeRange()

                  startAndEndTimeRange.forEach((item) => {
                    inputList.push(item)
                  })
                }

                //移除：自定义时间选择控件
                const dateTimePickerIndex = inputList.findIndex(
                  (item) => item.ref === 'dateTimePicker'
                )

                if (val < 5 && dateTimePickerIndex !== -1) {
                  inputList.splice(dateTimePickerIndex, 2)
                }
              },
            },
          ],
        },
        headers: [
          { label: '日期', props: 'created_at' },
          { label: '客服老师', props: 'teacher_name' },
          { label: '所在学校', props: 'xq_name' },
          { label: '学生名称', props: 'student_name' },
          { label: '学生性别', props: 'gender_str' },
          { label: '班级', props: 'class' },
          { label: '家长名称', props: 'parent_name' },
          { label: '家长手机号码', props: 'mobile' },
          { label: '订单类型', props: 'type_str' },
          { label: '充值方式', props: 'pay_type_str' },
          { label: '充值金额', props: 'recharge_money' },
          { label: '赠送金额', props: 'give_money' },
          { label: '审核状态', props: 'status_str' },
        ],
        titleBtns: [
          {
            type: 'filter',
            title: '查询',
          },
          {
            title: '充值',
            color: 'blue',
            authenKey: 'memberRecharge_add',
            onTap: async (options) => {
              this.renderRechargeDialog(0)
            },
          },
          {
            title: '批量审核',
            color: 'blue',
            authenKey: 'memberRecharge_examine',
            onTap: (options) => {
              try {
                const selectedTrList = this.selectedTrList || []

                const toExamineIDList = selectedTrList
                  .filter((item) => item.status === 2)
                  .map((item) => item.id)

                if (toExamineIDList <= 0) {
                  throw '请至少选择一个待审核的充值单'
                }

                this.examineRechargeByBatch(toExamineIDList)
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
          {
            title: '导出',
            color: 'normal',
            authenKey: 'order_export',
            onTap: async (thisBtn) => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: 'POST',
                  apiKey: 'memberCheckExport',
                  fileName: '充值列表.xls',
                  data: thisBtn.keyword || '',
                  submitByForm: true,
                })
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
        ],
        actionBtns: [
          {
            type: 'multiState', //多状态按钮
            props: 'status',
            authenKey: 'memberRecharge_examine',
            options: [
              {
                label: '审核',
                value: 2,
                color: 'success',
              },
              {
                label: '已审核',
                value: 1,
                color: 'warning',
              },
            ],
            onTap: async (btnObj, tableItem) => {
              try {
                const { status } = tableItem

                if (status !== 1) {
                  this.$resureDialog({
                    ok: () => {
                      this.examineRechargeByBatch([tableItem.id])
                    },
                  })
                } else {
                  throw '此单已审核完成'
                }
              } catch (error) {
                this.$catchError(error)
              }
            },
          },
          {
            title: '删除',
            color: 'red',
            authenKey: 'memberRecharge_del',
            onDelete: async (options) => {
              return await this.$ajax({
                responseAll: true,
                apiKey: 'delRechargeMember',
                data: {
                  id: options.id,
                },
              })
            },
          },
        ],
        select: {
          type: 'mutiple',
          onChange: (val) => {
            this.selectedTrList = val
          },
        },
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: 'getExamineRechargeMemberList',
            data: options,
          })

          return ret
        },
      }
    },

    getStartAndEndTimeRange() {
      return [
        {
          ref: 'dateTimePicker',
          type: 'dateTimePicker',
          label: '开始时间',
          placeholder: '请选择开始时间',
          rules: ['required', '!null'],
          value: null,
          props: 'start_time', //start_time	否	string	开始时间，当时间类型为5，该参数必须，例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd hh:mm:ss'
            )

            item.value = formatDate
          },
        },
        {
          type: 'dateTimePicker',
          label: '结束时间',
          placeholder: '请选择结束时间',
          maxLength: 40,
          rules: ['required', '!null'],
          value: null,
          props: 'end_time', //end_time	否	string	结束时间，当时间类型为5，该参数必须 例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime()
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              'yyyy-MM-dd hh:mm:ss'
            )

            item.value = formatDate
          },
        },
      ]
    },
  },
}
</script>

<!--
 * @Date         : 2020-11-21 17:46:55
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-11 18:07:17
 * @FilePath     : \leXue_manage_pc\src\views\goods\addOrEditGoods.vue
-->
<template>
  <div class="h-100 d-flex flex-column">
    <div class="col overflow-scroll goods-details" v-if="pageType !== null">
      <!-- 模块：基础信息 -->
      <baseInfoModules
        ref="baseInfo"
        :readonly="pageType === 2"
      ></baseInfoModules>

      <!-- 模块：规格 -->
      <skuModulesV2
        ref="sku"
        :readonly="pageType === 2"
        :pageType="pageType"
      ></skuModulesV2>

      <!-- 模块：其他信息 -->
      <otherGoodsInfoModules
        ref="otherGoodsInfo"
        :readonly="pageType === 2"
      ></otherGoodsInfoModules>
    </div>

    <!-- 模块：底部操作按钮 -->
    <footerBtnModules :btnList="curentPageFooterBtnList" />
  </div>
</template>

<script>
import skuModulesV2 from "./skuModulesV2.vue";
import baseInfoModules from "./baseInfoModules.vue";
import otherGoodsInfoModules from "./otherGoodsInfoModules.vue";
import footerBtnModules from "@/components/footerBtnModules.vue";

export default {
  components: {
    skuModulesV2,
    baseInfoModules,
    otherGoodsInfoModules,
    footerBtnModules,
  },
  data() {
    return {
      pageType: 0,
    };
  },
  async mounted() {
    try {
      let { pageType, id } = this.$route.query;

      pageType = this.pageType = Number(pageType);

      //1：编辑 2：查看
      if (pageType === 1 || pageType === 2) {
        const { res } = await this.$ajax({
          apiKey: "getProductDetails",
          data: {
            id,
          },
        });

        const goodsDetails = res;

        this.$refs.baseInfo.inputValue(goodsDetails); //填充基础信息
        this.$refs.sku.fillSkuList(goodsDetails.sku); //填充sku
        this.$refs.otherGoodsInfo.inputValue(goodsDetails); //填充sku

        this.goodsID = id; //产品id
        this.goodsDetails = goodsDetails;
      }
    } catch (error) {
      this.$catchError(error);
    }
  },
  methods: {
    //提交
    async handleSubmit(pageType) {
      try {
        if (this.isSubmitted_goods === true) {
          return false;
        }
        const { sku } = this.goodsDetails || {};

        const { getBaseInfo } = this.$refs.baseInfo;
        const { propsListModules, getSkuList } = this.$refs.sku;
        const { getOtherInfo } = this.$refs.otherGoodsInfo;

        const skuList = getSkuList();

        //特殊操作，如果该sku已经被引用了，就把它的sku_id回填，如果没引用或者新增就补全sku_id = 0;
        this.fillSkuID(propsListModules, sku, skuList);

        let apiKey;
        let toSubmitOptions = {
          ...getBaseInfo(), //基础信息：产品名称，一口价等
          skus: JSON.stringify(skuList), //sku列表
          ...getOtherInfo(), //其他信息：特色，适用人群，培养目标等
        };

        switch (
          pageType //0: 新增 1：编辑
        ) {
          case 0:
            apiKey = "addProduct";
            break;
          case 1:
            apiKey = "editProduct";
            toSubmitOptions.id = this.goodsID; //id 是	int	产品id
            break;
        }

        this.isSubmitted_goods = true;

        const { msg } = await this.$ajax({
          apiKey,
          data: toSubmitOptions,
        });

        this.$toast({
          color: "success",
          msg,
        });

        this.$bus_unique.emit("updateGoodsList");
        this.$router.back(-1);
      } catch (error) {
        this.isSubmitted_goods = false;
        this.$catchError(error);
      }
    },

    //产品sku_id,当sku_id = 0为所有sku新增 ，否则为编辑sku
    fillSkuID(propsListModules, sku, skuList) {
      if (sku) {
        let propsList = propsListModules.map((item) => item.props);
        let propsListLen = propsList.length;

        //筛选: 生成sku_id 对应的sku条件
        let _commandList = sku.map((itemSku) => {
          const { id, checked } = itemSku;

          if (checked === 1) {
            let _command = {};
            propsList.forEach((item) => {
              _command[item] = itemSku[item];
            });

            return {
              id: itemSku.id,
              command: _command,
            };
          } else {
            return null;
          }
        });

        _commandList = _commandList.filter((item) => item);

        //匹配: 把原来被使用的sku_id赋值回去
        _commandList.forEach((item) => {
          const { id, command } = item;

          const currentObj = skuList.find((item) => {
            console.info("command", command);
            for (let key in command) {
              if (item[key] !== command[key]) {
                return false;
              }
            }
            return true;
          });

          console.info("item", item);
          console.info("currentObj", currentObj);

          if (currentObj) {
            currentObj.sku_id = id; //赋值
          }
        });

        _commandList = null; //销毁闭包
      }

      skuList.forEach((item) => {
        if (!item.sku_id) {
          item.sku_id = 0; //默认为sku_id = 0;
        }
      });
    },
  },
  computed: {
    //页面底部的操作按钮
    curentPageFooterBtnList() {
      let btnList = [];
      let pageType = this.pageType;

      switch (
        pageType //0: 新增 1：编辑
      ) {
        case 0:
          btnList = [
            {
              title: "提交产品信息",
              authenKey: "goods_add",
              onTap: () => {
                this.handleSubmit(pageType);
              },
            },
          ];
          break;
        case 1:
          btnList = [
            {
              title: "保存编辑",
              authenKey: "goods_details",
              onTap: () => {
                this.handleSubmit(pageType);
              },
            },
          ];
          break;
      }
      return btnList;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-details .main-content-div {
  height: initial;
}
// 模块：普通信息
.normal-info-modules {
  .body-title {
    padding: 0.85rem 0.5rem 0.85rem 1.25rem;
    font-size: 0.8rem;
    color: #606060;

    .sub-title {
      font-size: 0.7rem;
      color: #606060;
    }
  }

  .body-label {
    padding-left: 0.55rem;
    padding-right: 0.55rem;
    background-color: #333744;
    border-radius: 0.2rem;
    border: solid 0.05rem #cccccc;
    line-height: 1.5rem;
    font-size: 0.7rem;
    color: #ffffff;
  }

  .body-table {
    padding-left: 1.15rem;
    border-top: 1px solid #eaeaea;
  }
}

::v-deep .el-button {
  line-height: 1.5rem;
  padding: 0 0.5rem;
  background-color: #333744;
  border-radius: 0.2rem;
  font-size: 0.7rem;
  color: #ffffff;
}
</style>
<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-29 09:53:19
 * @FilePath     : \leXue_manage_pc\src\views\customer\parentList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>

    <!-- 新增/编辑家长 -->
    <addOrEditParent ref="addOrEditParent"></addOrEditParent>

    <!-- 账户合并 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="FormDialogBox"
      width="700px"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="账户合并"
      @close="cancel()"
    >
      <el-form :model="form" :rules="rules" ref="formRef" label-width="auto">
        <el-form-item label="需要转移的手机号：" prop="old_mobile">
          <el-input
            v-model="form.old_mobile"
            placeholder="请输入需要转移的手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="目标手机号：" prop="new_mobile">
          <el-input
            v-model="form.new_mobile"
            placeholder="请输入目标手机号"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine(1)">确定</el-button>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog
      :visible.sync="studentDetailsDialogVisible"
      class="studentDetailsDialogBox"
      width="700px"
      append-to-body
      title="查看详情"
      fullscreen
    >
      <studentDetails
        v-if="studentDetailsDialogVisible"
        :dialog="true"
        :pageType="2"
        :studentID="studentID"
        :parentID="parentID"
      />
    </el-dialog>
  </mainContentDiv>
</template>

<script>
import addOrEditParent from "./addOrEditParent.vue";
import studentDetails from "./studentDetails.vue";

export default {
  components: {
    addOrEditParent,
    studentDetails,
  },
  data() {
    return {
      tableOptions: null,
      dialogVisible: false,
      studentDetailsDialogVisible: false,
      studentID: null,
      parentID: null,
      form: {
        old_mobile: "",
        new_mobile: "",
      },
      rules: {
        old_mobile: [
          {
            required: true,
            message: "请输入需要转移的手机号",
            trigger: "blur",
          },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
        new_mobile: [
          { required: true, message: "请输入目标手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
      },
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.tableOptions = this.getTableOptions();

    this.$bus_unique.on("updateAuthenList", "authenList", () => {
      this.$refs.tableList.init();
    });
  },
  destroyed() {
    this.$bus_unique.off("updateAuthenList");
  },
  methods: {
    getTableOptions() {
      const isSelect = this.isSelect; //选择模式

      let configureOptions;

      if (isSelect === true) {
        configureOptions = {
          actionBtns: [
            {
              title: "选择",
              color: "cyan",
              isRender: true,
              onTap: (options) => {
                this.$emit("change", options);
              },
            },
          ],
        };
      } else {
        configureOptions = {
          titleBtns: [
            {
              type: "filter",
              title: "查询",
            },
            {
              title: "新增家长",
              authenKey: "parent_add",
              onTap: async () => {
                await this.$refs.addOrEditParent.render({
                  type: 0,
                });
                this.$refs.tableList.init(); //刷新数据
              },
            },
            {
              title: "导出",
              color: "normal",
              authenKey: "parent_export",
              onTap: async (thisBtn) => {
                try {
                  console.info("thisBtn", thisBtn);

                  await this.$ajax({
                    isExportFile: true, //导出文件
                    methods: "POST",
                    apiKey: "exportParentList",
                    fileName: "家长列表.xls",
                    data: thisBtn.keyword || "",
                    submitByForm: true,
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              title: "导入模板下载",
              color: "normal",
              authenKey: "parent_importTemplate",
              onTap: async () => {
                try {
                  await this.$ajax({
                    isExportFile: true, //导出文件
                    methods: "POST",
                    apiKey: "importParentListTemplate",
                    fileName: "家长导入模板.xls",
                  });
                } catch (error) {
                  this.$catchError(error);
                }
              },
            },
            {
              type: "import",
              title: "导入",
              color: "normal",
              authenKey: "parent_import",
              onRequest: async (currentSelectedFile) => {
                const ret = await this.$ajax({
                  responseAll: true,
                  methods: "POST",
                  apiKey: "importParentList",
                  submitByForm: true,
                  data: {
                    file: currentSelectedFile,
                  },
                });

                return ret;
              },
            },
            {
              title: "账户合并",
              color: "cyan",
              authenKey: "parent_account_merge",
              onTap: async (thisBtn) => {
                console.log("点击");
                this.dialogVisible = true;
              },
            },
          ],
          actionBtns: [
            {
              title: "查看",
              color: "cyan",
              authenKey: "parent_details",
              onTap: (options) => {
                this.parentID = options.id;
                this.studentDetailsDialogVisible = true;

                // this.$router.push({
                //   name: 'studentDetails',
                //   query: {
                //     pageType: 2,
                //     parentID: options.id,
                //   },
                // })
              },
            },
            {
              title: "查看订单",
              color: "warning",
              onTap: (options) => {
                // console.log('查看订单', options)
                this.$router.push({
                  name: "orderList",
                  query: {
                    mobile: options.mobile,
                  },
                });
              },
            },
            {
              title: "编辑",
              color: "cyan",
              authenKey: "parent_edit",
              onTap: async (options) => {
                // console.log('options', options)
                await this.$refs.addOrEditParent.render({
                  type: 1,
                  inputedVal: options,
                });
                this.$refs.tableList.init(); //刷新数据
              },
            },
            {
              title: "删除",
              color: "red",
              authenKey: "parent_del",
              onDelete: async (options) => {
                return await this.$ajax({
                  responseAll: true,
                  apiKey: "delParent",
                  data: {
                    id: options.id,
                  },
                });
              },
            },
          ],
        };
      }

      return {
        authenKey: "parent_list",
        search: {
          inputList: [
            {
              type: "input",
              label: "家长名称",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生名称",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长手机号码",
              maxLength: 11,
              value: "",
              props: "mobile",
              rules: ["!null, mobile"],
            },
            {
              type: "input",
              label: "备注",
              value: "",
              props: "remark",
              rules: ["!null"],
            },
          ],
        },
        headers: [
          { label: "家长名称", props: "name" },
          { label: "家长手机号码", props: "mobile" },
          { label: "学生名称", props: "student", type: "text" },
          { label: "购买产品数量", props: "num" },
          { label: "消费总金额", props: "money" },
          { label: "跟进客服（老师）", props: "teacher_name" },
          { label: "原存费", props: "ycf_balance" },
          { label: "备注", props: "remark" },
        ],
        ...configureOptions,
        getData: async (options) => {
          const ret = await this.$ajax({
            apiKey: "getParentList",
            data: options,
          });

          return ret;
        },
      };
    },

    determine() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;

        this.$confirm("是否确认合并？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await this.$ajax({
              apiKey: "account_merge",
              data: this.form,
            });

            this.$message({
              type: "success",
              message: "合并成功",
            });

            this.dialogVisible = false;
            this.$refs.tableList.init(); //刷新数据
          })
          .catch(() => {});
      });
    },
    cancel() {
      this.form.old_mobile = "";
      this.form.new_mobile = "";
    },
  },
};
</script>

<style lang="scss">
.studentDetailsDialogBox {
  .el-dialog__body {
    height: 94%;
    padding: 0 10px 20px;
    overflow: hidden;
    background: #f4f4f4;
  }
}
</style>

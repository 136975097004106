import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "home",
  },
  //侧边栏布局
  {
    path: "/",
    component: () => import("@/views/sideLayout.vue"),
    children: [
      //home
      {
        path: "home",
        name: "home",
        meta: {
          title: "主界面",
        },
        component: () => import("@/views/home.vue"),
      },
      {
        path: "editMyUserInfo",
        name: "editMyUserInfo",
        meta: {
          title: "修改密码",
        },
        component: () => import("@/views/user/editMyUserInfo.vue"),
      },

      //权限
      {
        path: "authenList",
        name: "authenList",
        meta: {
          title: "角色列表",
        },
        component: () => import("@/views/authen/authenList.vue"),
      },
      {
        path: "/authenList/addOrEditAuthen",
        name: "addOrEditAuthen",
        meta: {
          title: {
            0: "新增角色",
            1: "编辑角色",
            2: "查看角色",
          },
        },
        component: () => import("@/views/authen/addOrEditAuthen.vue"),
      },

      //管理员
      {
        path: "adminList",
        name: "adminList",
        meta: {
          title: "后台管理员",
        },
        component: () => import("@/views/admin/adminList.vue"),
      },
      {
        path: "/adminList/addOrEditAdmin",
        name: "addOrEditAdmin",
        meta: {
          title: {
            0: "新增管理员",
            1: "编辑管理员",
            2: "管理员详情",
          },
        },
        component: () => import("@/views/admin/addOrEditAdmin.vue"),
      },
      {
        path: "/adminList/editPd",
        name: "adminEditPd",
        meta: {
          title: "修改密码",
        },
        component: () => import("@/views/admin/editPd.vue"),
      },

      //操作日志
      {
        path: "operateLog",
        name: "operateLog",
        meta: {
          title: "操作日志",
        },
        component: () => import("@/views/other/operateLog.vue"),
      },

      //学校
      {
        path: "schoolList",
        name: "schoolList",
        meta: {
          title: "学校列表",
        },
        component: () => import("@/views/school/schoolList.vue"),
      },

      //商品
      {
        path: "goodsList",
        name: "goodsList",
        meta: {
          title: "产品列表",
        },
        component: () => import("@/views/goods/goodsList.vue"),
      },
      {
        path: "/goodsList/addOrEditGoods",
        name: "addOrEditGoods",
        meta: {
          title: {
            0: "新增产品",
            1: "编辑产品",
            2: "产品详情",
          },
        },
        component: () => import("@/views/goods/addOrEditGoods.vue"),
      },

      //产品组合
      {
        path: "mutilGoodsList",
        name: "mutilGoodsList",
        meta: {
          title: "产品组合列表",
        },
        component: () => import("@/views/goods/mutilGoodsList.vue"),
      },
      {
        path: "/mutilGoodsList/addOrEditMutilGoods",
        name: "addOrEditMutilGoods",
        meta: {
          title: {
            0: "新增产品组合",
            1: "编辑产品组合",
          },
        },
        component: () => import("@/views/goods/addOrEditMutilGoods.vue"),
      },

      //客户
      {
        path: "parentList",
        name: "parentList",
        meta: {
          title: "家长列表",
        },
        component: () => import("@/views/customer/parentList.vue"),
      },
      {
        path: "/customer/addOrEditCustomer",
        name: "addOrEditCustomer",
        meta: {
          title: "",
        },
        component: () => import("@/views/customer/addOrEditCustomer.vue"),
      },

      //学生
      {
        path: "studentList",
        name: "studentList",
        meta: {
          title: "学生列表",
        },
        component: () => import("@/views/customer/studentList.vue"),
      },
      {
        path: "studentDetails",
        name: "studentDetails",
        meta: {
          title: {
            0: "",
            1: "学生信息",
            2: "家长信息",
          },
        },
        component: () => import("@/views/customer/studentDetails.vue"),
      },

      //学生关系
      {
        path: "relationshipList",
        name: "relationshipList",
        meta: {
          title: "学生关系",
        },
        component: () => import("@/views/customer/relationshipList.vue"),
      },

      //订单
      {
        path: "orderList",
        name: "orderList",
        meta: {
          title: "订单列表",
        },
        component: () => import("@/views/order/orderList.vue"),
      },
      {
        path: "refundOrderList",
        name: "refundOrderList",
        meta: {
          title: "退款订单列表",
        },
        component: () => import("@/views/order/refundOrderList.vue"),
      },
      {
        path: "addOrEditOrder",
        name: "addOrEditOrder",
        meta: {
          title: {
            0: "新增订单",
            1: "编辑订单",
            2: "订单详情",
            3: "订单退款",
          },
        },
        component: () => import("@/views/order/addOrEditOrder.vue"),
      },

      //退款理由
      {
        path: "refundReasonList",
        name: "refundReasonList",
        meta: {
          title: "退款理由列表",
        },
        component: () => import("@/views/refundReason/refundReasonList.vue"),
      },

      //财务统计
      {
        path: "finance",
        name: "financeIndex",
        meta: {
          title: "财务统计报表",
        },
        component: () => import("@/views/finance/financeIndex.vue"),
      },
      {
        path: "finaceOrderDetails",
        name: "finaceOrderDetails",
        meta: {
          title: "财务订单详情",
        },
        component: () => import("@/views/finance/finaceOrderDetails.vue"),
      },

      // 课时统计
      {
        path: "classStatistics",
        name: "classStatisticsIndex",
        meta: {
          title: "课时统计",
        },
        component: () => import("@/views/classStatistics/index.vue"),
      },
      {
        path: "classStatisticsDetails",
        name: "classStatisticsDetails",
        meta: {
          title: "课时统计详情",
        },
        component: () => import("@/views/classStatistics/details.vue"),
      },

      //班级
      {
        path: "classList",
        name: "classList",
        meta: {
          title: "班级列表",
        },
        component: () => import("@/views/class/classList.vue"),
      },
      {
        path: "addOrEditClass",
        name: "addOrEditClass",
        meta: {
          title: {
            0: "新增班级",
            1: "编辑班级",
          },
        },
        component: () => import("@/views/class/addOrEditClass.vue"),
      },
      {
        path: "classDetails",
        name: "classDetails",
        meta: {
          title: "班级详情",
        },
        component: () => import("@/views/class/classDetails.vue"),
      },
      {
        path: "ondutyStudentList",
        name: "ondutyStudentList",
        meta: {
          title: "出勤学生列表",
        },
        component: () => import("@/views/class/ondutyStudentList.vue"),
      },
      {
        path: "absenceStudentList",
        name: "absenceStudentList",
        meta: {
          title: "缺勤学生列表",
        },
        component: () => import("@/views/class/absenceStudentList.vue"),
      },

      //会员
      {
        path: "memberList",
        name: "memberList",
        meta: {
          title: "会员资料",
        },
        component: () => import("@/views/member/memberList.vue"),
      },
      {
        path: "memberLevel",
        name: "memberLevel",
        meta: {
          title: "会员等级",
        },
        component: () => import("@/views/member/memberLevel.vue"),
      },
      {
        path: "rechargeRuleList",
        name: "rechargeRuleList",
        meta: {
          title: "充值规则",
        },
        component: () => import("@/views/member/rechargeRuleList.vue"),
      },
      {
        path: "recharge",
        name: "recharge",
        meta: {
          title: "充值",
        },
        component: () => import("@/views/member/recharge.vue"),
      },

      // 教师管理
      {
        path: "teacherServiceList",
        name: "teacherServiceList",
        meta: {
          title: "教师列表",
        },
        component: () => import("@/views/teacher/index.vue"),
      },
      {
        path: "teacherDepartment",
        name: "teacherDepartment",
        meta: {
          title: "部门管理",
        },
        component: () => import("@/views/teacher/department.vue"),
      },
      {
        path: "teacherLog",
        name: "teacherLog",
        meta: {
          title: "操作日志",
        },
        component: () => import("@/views/teacher/log.vue"),
      },
      /* 教学管理 */
      {
        path: "investigating",
        name: "investigating",
        meta: {
          title: "教研组列表",
        },
        component: () => import("@/views/teaching/investigating.vue"),
      },
      {
        path: "curriculumSystem",
        name: "curriculumSystem",
        meta: {
          title: "课程体系",
        },
        component: () => import("@/views/teaching/curriculumSystem.vue"),
      },
      {
        path: "syllabus",
        name: "syllabus",
        meta: {
          title: "教学大纲",
        },
        component: () => import("@/views/teaching/syllabus/index.vue"),
      },
      {
        path: "addOrEditSyllabus",
        name: "addOrEditSyllabus",
        meta: {
          title: {
            0: "教学大纲-新增",
            1: "教学大纲-编辑",
          },
        },
        component: () => import("@/views/teaching/syllabus/addOrEdit.vue"),
      },
      {
        path: "showSyllabus",
        name: "showSyllabus",
        meta: {
          title: "教学大纲-查看",
        },
        component: () => import("@/views/teaching/syllabus/show.vue"),
      },
      {
        path: "evaluationScore",
        name: "evaluationScore",
        meta: {
          title: "教学大纲-查看-考核评分",
        },
        component: () =>
          import("@/views/teaching/syllabus/evaluationScore.vue"),
      },
      {
        path: "inspectionTemplate",
        name: "inspectionTemplate",
        meta: {
          title: "考核模板",
        },
        component: () => import("@/views/teaching/inspectionTemplate.vue"),
      },
      /* 排课管理 */
      {
        path: "arrangingTable",
        name: "arrangingTable",
        meta: {
          title: "排课表列表",
        },
        component: () => import("@/views/arranging/arrangingTable/index.vue"),
      },
      {
        path: "arrangingTableShow",
        name: "arrangingTableShow",
        meta: {
          title: "排课表",
        },
        component: () => import("@/views/arranging/arrangingTable/show.vue"),
      },
      /* 教务小程序管理 */
      {
        path: "projectTemplate",
        name: "projectTemplate",
        meta: {
          title: "项目模板",
        },
        component: () =>
          import("@/views/educationalMiniApp/projectTemplate/index.vue"),
      },
      {
        path: "projectTemplateAddOrEdit",
        name: "projectTemplateAddOrEdit",
        meta: {
          title: {
            0: "项目模板-新增",
            1: "项目模板-编辑",
            2: "项目模板-详情",
          },
        },
        component: () =>
          import("@/views/educationalMiniApp/projectTemplate/addOrEdit.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/user/login.vue"),
  },
  {
    path: "/testIndex",
    name: "test",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/test/testIndex.vue"),
  },

  //other
  {
    path: "*",
    name: "*",
    meta: {
      title: "404页面",
    },
    component: () => import("@/404.vue"),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

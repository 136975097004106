<!--
 * @Description: 课时统计-详情
-->
<template>
  <mainContentDiv class="pa-20">
    <el-page-header @back="$router.go(-1)" :content="'班级名称：' + className">
    </el-page-header>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 20px"
      border
      stripe
      height="750"
      ref="tableRef"
    >
      <!-- 动态配置 -->
      <template v-for="(item, index) in tableColumnList">
        <el-table-column
          :key="index"
          v-if="item.prop === 'clock'"
          :label="item.label"
          align="center"
        >
          <el-table-column
            align="center"
            width="100"
            label="课时费用"
            :prop="item.prop"
            :index="item.index"
          >
            <template slot-scope="scope">
              {{ scope.row.clock[scope.column.index].price || "-" }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="160"
            label="销课时间 "
            :prop="item.prop"
            :index="item.index"
          >
            <template slot-scope="scope">
              {{ scope.row.clock[scope.column.index].created_at || "-" }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :key="index"
          v-else
          :label="item.label"
          :prop="item.prop"
          :index="item.index"
          align="center"
          min-width="140"
          fixed="left"
        >
          <template slot-scope="scope">
            {{ scope.row[scope.column.property] }}</template
          >
        </el-table-column>
      </template>
    </el-table>
  </mainContentDiv>
</template>

<script>
export default {
  name: "classStatisticsDetails",
  data() {
    return {
      className: "",
      tableData: [],
      tableColumnList: [],
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let { id } = this.$route.query;
      console.log("id", id);
      let { res } = await this.$ajax({
        apiKey: "finance_class_count",
        data: {
          cid: id,
        },
      });
      console.log("获取详情", res);

      this.className = res.name;
      this.tableData = res.student;

      if (res.student.length) {
        // 处理动态表头
        let classColumnList = [];
        res.student[0].clock.forEach((item, index) => {
          classColumnList.push({
            index,
            prop: "clock",
            label: `第${index + 1}课时`,
          });
        });
        console.log("classColumnList", classColumnList);

        this.tableColumnList = [
          {
            label: "学生姓名",
            prop: "name",
          },
          ...classColumnList,
        ];
        console.log("tableColumnList", this.tableColumnList);
      }
      
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

<!--
 * @Date         : 2020-11-18 09:36:20
 * @LastEditors  : cxx
 * @LastEditTime : 2021-03-20 16:25:57
 * @FilePath     : \leXue_manage_pc\src\views\order\refundOrderList.vue
-->
<template>
  <mainContentDiv class="pa-20">
    <tableList
      ref="tableList"
      v-if="tableOptions"
      :initOptions="tableOptions"
    ></tableList>
  </mainContentDiv>
</template>

<script>
export default {
  props: {
    initOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableOptions: null,
    };
  },
  mounted() {
    this.tableOptions = this.getTableOptions();
  },
  methods: {
    getTableOptions() {
      let getTableOptions = {
        authenKey: "order_refundRecordList",
        search: {
          inputList: [
            {
              type: "input",
              label: "产品名称",
              placeholder: "",
              value: "",
              props: "pro_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "学生姓名",
              placeholder: "",
              value: "",
              props: "student_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "家长姓名",
              placeholder: "",
              value: "",
              props: "parent_name",
              rules: ["!null"],
            },
            {
              type: "input",
              label: "所属校区",
              placeholder: "",
              value: "",
              props: "xq_name", //xq_name	否	string	校区名称关键词
              rules: ["!null"],
            },
            {
              type: "input",
              label: "消费地区",
              placeholder: "",
              value: "",
              props: "area_name", //area_name	否	string	地区名称
              rules: ["!null"],
            },
            {
              type: "input",
              label: "原订单号",
              placeholder: "",
              value: "",
              maxLength: 20,
              props: "order_id", //order_id	否	string	原订单编号
              rules: ["!null"],
            },
            {
              type: "input",
              label: "退款订单号",
              placeholder: "",
              value: "",
              maxLength: 20,
              props: "refund_oid", //refund_oid	否	string	退款单号
              rules: ["!null"],
            },
            {
              type: "radioBtnList",
              label: "时间筛选",
              value: 0,
              props: "time_type", //time_type	否	int	时间类型 1-本月 2-上月 3-本年 4-去年 5-自定义
              rules: ["!null"],
              options: [
                {
                  label: "全部",
                  value: 0,
                },
                {
                  label: "本月",
                  value: 1,
                },
                {
                  label: "上月",
                  value: 2,
                },
                {
                  label: "本年",
                  value: 3,
                },
                {
                  label: "去年",
                  value: 4,
                },
                {
                  label: "自定义",
                  value: 5,
                },
              ],
              onChange: (val, thisObj, inputList) => {
                //增加：自定义时间选择控件
                if (val === 5) {
                  const startAndEndTimeRange = this.getStartAndEndTimeRange();

                  startAndEndTimeRange.forEach((item) => {
                    inputList.push(item);
                  });
                }

                //移除：自定义时间选择控件
                const dateTimePickerIndex = inputList.findIndex(
                  (item) => item.ref === "dateTimePicker"
                );

                if (val < 5 && dateTimePickerIndex !== -1) {
                  inputList.splice(dateTimePickerIndex, 2);
                }
              },
            },
          ],
        },
        headers: [
          { label: "原订单号", props: "order_id" },
          { label: "退款单号", props: "refund_oid" },
          { label: "学生名称", props: "student_name" },
          { label: "规格名称", props: "pro_name" },
          { label: "分类", props: "classify_name" },
          { label: "所属校区", props: "xq_name" },
          { label: "退款日期", props: "refund_date" },
          { label: "退款原因", props: "refund_reason" },
          { label: "退回课时", props: "refund_count" },
          { label: "退回金额", props: "refund_price" },
        ],
        titleBtns: [
          {
            type: "filter",
            title: "查询",
          },
          {
            title: "导出",
            color: "normal",
            authenKey: "order_refund_export",
            onTap: async (thisBtn) => {
              try {
                await this.$ajax({
                  isExportFile: true, //导出文件
                  methods: "POST",
                  apiKey: "exportRefundOrder",
                  fileName: "退款订单列表.xls",
                  data: thisBtn.keyword || "",
                  submitByForm: true,
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        actionBtns: [
          {
            type: "multiState", //多状态按钮
            props: "status",
            options: [
              {
                label: "通过",
                value: 1,
                color: 'success',
              },
              {
                label: "待处理",
                value: 2,
                color: 'warning',
              },
              {
                label: "未通过",
                value: 3,
                color: 'danger',
              },
            ],
            /**
             * @description: 比对当前status 和 options 中的value值，计算出当前option是否disable
             * @param {}     // optVal 当前option item 的值;  btnObj，当前按钮;  tableTr 当前数据
             * @return {*}
             */
            computedOptIsDisable: (optVal, btnObj, tableTr) => {
              const { status } = tableTr;

              let disable;

              switch (status) { //1: 通过 2：待处理 3: 未通过（跟 btnObj的options匹配）
                case 1:
                  disable = true;
                  break;
                case 2:
                  const retMap = {
                    1: false,
                    2: true,
                    3: false,
                  }
                  disable = retMap[optVal];
                  break;
                case 3:
                  disable = true;
                  break;
              }

              return disable;
            },
            onSelect: async (newStatus, btnObj, tableItem) => {
              try {
                const { status: currentStatus } = tableItem;

                if (newStatus === currentStatus) {
                  console.info("不可提交同个状态");
                  return false;
                }

                this.$resureDialog({
                  ok: async () => {
                    const { msg } = await this.$ajax({
                      apiKey: "checkRefundOrder",
                      data: {
                        id: tableItem.id,
                        status: newStatus,
                      },
                    });

                    tableItem[btnObj.props] = newStatus;

                    this.$bus_unique.emit('updateUnCheckOrderQty');
                    this.$toast({
                      msg,
                    });
                  },
                });
              } catch (error) {
                this.$catchError(error);
              }
            },
          },
        ],
        getData: async (options) => {
          if (options.time_type === 0) {
            delete options.time_type;
          }

          const { orderID, parentID } = this.initOptions;

          let ret = await this.$ajax({
            apiKey: "getRefundOrderList",
            data: {
              ...options,
              oid: orderID || "", //oid	否	int	订单id
              pid: parentID || "", //pid	否	int	家长id
            },
          });

          console.info("ret---------", ret);

          this.$emit("expendChange", ret.res.expend); //总收入

          return {
            code: ret.code,
            res: ret.res.list,
          };
        },
      };
      return getTableOptions;
    },

    getStartAndEndTimeRange() {
      return [
        {
          ref: "dateTimePicker",
          type: "dateTimePicker",
          label: "开始时间",
          placeholder: "请选择开始时间",
          rules: ["required", "!null"],
          value: null,
          props: "start_time", //start_time	否	string	开始时间，当时间类型为5，该参数必须，例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
        {
          type: "dateTimePicker",
          label: "结束时间",
          placeholder: "请选择结束时间",
          maxLength: 40,
          rules: ["required", "!null"],
          value: null,
          props: "end_time", //end_time	否	string	结束时间，当时间类型为5，该参数必须 例如：2020-10-10 10:00:00
          onChange: (val, item) => {
            const timeStamp = new Date(val).getTime();
            const formatDate = this.$com.formatDate(
              new Date(timeStamp),
              "yyyy-MM-dd hh:mm:ss"
            );

            item.value = formatDate;
          },
        },
      ];
    },
  },
};
</script>
